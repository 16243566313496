import React, { Component } from 'react';
import {connect} from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Col, Row, Button, Container } from 'reactstrap';
import { faqSetAll, newSetAll } from '../HomeMain/Reducer/HomeMainAction';
import FaqCard from './FaqCard';
import New from './New/New';
import './Faq.scss';
import { getBgColor, getFAQContainerBorderStyle, getFAQSectionTitleClass, getFAQTitleClass } from '../../config/Company/CompanyHelper';
import FaqApi from './FaqApi';
import FaqContactUs from './FaqContactUs';
import HomeApi from '../HomeMain/HomeApi';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAllFAQ: false,
      viewAllResources: false,
      contactInfo: {
        name: null,
        title: null,
        phone: null,
        email: null,
        photoUrl: 'https://rebate-portal.s3.amazonaws.com/lops/blank-profile-pic.png',
        bookUrl: null,
        isLoading: true
      }
    }
  }

  componentDidMount() {
    const { dispatch, companyId } = this.props;
    FaqApi.getContactInfo().then((res) => {
      const data = res.data.data;
      this.setState({
        contactInfo: {
          name: data.name || null,
          title: data.title || null,
          phone: data.phone || data.mobilePhone || null,
          email: data.email || null,
          photoUrl: 'https://rebate-portal.s3.amazonaws.com/lops/blank-profile-pic.png',
          // photoUrl: data.photo || data.fullPhotoUrl || data.smallPhotoUrl || 'https://rebate-portal.s3.amazonaws.com/lops/blank-profile-pic.png',
          bookUrl: data.bookUrl || null,
          isLoading: false
        }
      })
    }).catch(err => {
      this.setState({
        contactInfo: {
          ...this.state.contactInfo,
          isLoading: false
        }
      });
    });

    HomeApi.getFaqCompany(companyId).then(({data}) => {
      dispatch(faqSetAll(data.data));
    });
    //Get FAQ Resource
    HomeApi.getFaqResourceCompany(companyId).then(({data}) => {
      dispatch(newSetAll(data.data));
    });
  }

  clickShow = (idTitle, id) => {
    const { faqAll, dispatch } = this.props;
    dispatch(faqSetAll(
      faqAll.map((m) => {
        if(idTitle !== m.idTitle) return m;
        return {
         ...m,
         faqs : m.faqs.map((a) => {
          if(id !== a.id) return a;
          return {
            ...a,
            show: !a.show
          }
         })
        }
      })
    ))
  }

  onViewAllFAQ = () => {
    this.setState({
      viewAllFAQ: true
    });
  }

  onViewAllResources = () => {
    this.setState({
      viewAllResources: true
    });
  }

  onLearnMore = () => {
    window.open('https://buyersedgeplatform.com/supply-chain-support-center/', '_blank');
  }

  render() {
    const { viewAllFAQ, viewAllResources, contactInfo } = this.state;
    const { companyId, faqAll, newAll, dispatch } = this.props;
    const bgColor = getBgColor(companyId);
    const faqInit = faqAll.slice(0, 2);
    const newInit = newAll.slice(0, 6);
    const faqArr = viewAllFAQ ? faqAll : faqInit;
    const newArr = viewAllResources ? newAll : newInit;
    const containerBorderStyle = getFAQContainerBorderStyle(companyId);
    const sectionTitleClass = getFAQSectionTitleClass(companyId);

    const faqMap = faqArr.map((m, i) => (
      <Row key={`faq_${i}`}>
        <Col>
          <Row className={'faq-title-card mx-0 ' + (isMobile ? 'my-3 ' : 'my-3 ') + getFAQTitleClass(companyId)}>
            { m.title }
          </Row>
          <FaqCard  {...m} clickShow={this.clickShow} />
        </Col>
      </Row>
    ));

    const newProps = {
      dispatch,
      newArr
    };

    const contactUsProps = {
      companyId, ...contactInfo,
      containerBorderStyle,
      sectionTitleClass
    }

    return (
      <Container fluid className={`${bgColor}`}>
        { companyId !== 18 &&
        <>
          <Row className='mt-5'>
            <Col className='line-height-38 text-size-32 font-weight-bold text-center'>
              Supply Chain Support Center
            </Col>
          </Row>
          <Row className='mt-4 justify-content-center'>
            <Col className='text-center' style={{maxWidth: '460px'}}>
              Stay up to date on all things supply chain — from news, tips, insights, videos, recommendations, and much more!
            </Col>
          </Row>
          <Row className={(isMobile ? 'mx-0 py-2 mb-2' : 'mx-0 py-4')}>
            <Col className='text-center'>
              <Button onClick={this.onLearnMore} active style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' color='primary'>LEARN MORE</Button>
            </Col>
          </Row>
        </>
        }
        <Row>
          <Col xs={isMobile ? 12 : 6}>
            <Row className={'faq-title ' + (isMobile ? 'my-4 mx-0 ' : 'my-4 mx-1') + sectionTitleClass}>
              <Col>Frequently Asked Questions</Col>
            </Row>
            <Row className='mx-0'>
              <Col className='bg-white rounded' style={{ border: containerBorderStyle }}>
                { faqMap }
                {
                  !viewAllFAQ &&
                  <Row className={(isMobile ? 'mx-0 py-2 mb-2' : 'mx-0 py-4')}>
                    <Button active block style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' size='sm' color='primary'onClick={this.onViewAllFAQ} >VIEW ALL</Button>
                  </Row>
                }
              </Col>
            </Row>
          </Col>
          <FaqContactUs {...contactUsProps} />
        </Row>
        { newAll.length > 0 &&
          <Row className={(isMobile ? 'mx-0' : 'my-4 mx-4')}>
            <Col xs={12} className={'faq-title ' + (isMobile ? 'my-4 px-0 ' : 'my-4 px-0 ') + sectionTitleClass}>
              How To
            </Col>
            <Row className={'bg-white rounded ' + (isMobile ? 'mx-0' : 'mx-0')} style={{ border: containerBorderStyle }}>
              <Col xs={12} className='px-0'>
                <New {...newProps} />
              </Col>
              <Col className='pb-4' xs={12}>
                <Button active block style={{width: isMobile ? '100%' : ''}} className='py-2 shadow-none' size='sm' color='primary'onClick={this.onViewAllResources} >VIEW ALL</Button>
              </Col>
            </Row>
          </Row>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.company.id,
    faqAll: state.homeMain.faqAll,
    newAll: state.homeMain.newAll
  };
};

export default connect(mapStateToProps)(Faq);
