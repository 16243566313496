import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import SignUpApi from '../../SignUp/SignUpApi';
import { signUpSetAllSalesRep } from '../../SignUp/Reducer/SignUpAction';

const useCreatePassword = props => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const onChangePassword = input => setPassword(input);
  const onChangeConfirmPassword = input => setConfirmPassword(input);

  const onNext = (e) => {
    e.preventDefault();
    const { recordId, history } = props;
    let info;
    if (password === '' || confirmPassword === '') {
      alert('Fields can not be empty');
      return;
    }
    if (password !== confirmPassword) {
      alert('Fields can not be different');
      return;
    }
    const getSalesRepSignUpInfo = new Promise((resolve, reject) => {
      dispatch(spinner(true));
      SignUpApi.getSalesRepSignUpInfo(recordId)
        .then(({ data }) => {
          info = JSON.parse(data.data.payload);
          info.salesRepSignUpId = recordId;
          info.step1Inputs.password = password;
          info.step1Inputs.passwordRepeat = confirmPassword;
          dispatch(signUpSetAllSalesRep(info));
          resolve();
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });

    Promise.all([getSalesRepSignUpInfo])
      .then(values => {
        dispatch(spinner(false));
        history.push('/register/new/4');
      })
      .catch(error => {
        dispatch(spinner(false));
        console.log(error)
      });
  }

  return {
    password,
    confirmPassword,
    onChangePassword,
    onChangeConfirmPassword,
    onNext
  }
}

export default useCreatePassword;