import React, { Component } from 'react'
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import Card from '../../MasterComponents/Card/Card';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Button from '../../MasterComponents/Button/Button';
import Input from '../../MasterComponents/Input/Input';
import { changePage } from './DashboardActions';
import DropDownListSelect from '../../MasterComponents/DropDownList/DropDownListSelect';
import DashboardApi from './DashboardApi';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { isMobile } from 'react-device-detect';
import byGoogle from '../../assets/images/powered-by-google.png';
import ReactGA from 'react-ga';
import SignUpApi from '../SignUp/SignUpApi';
import numeral from 'numeral';
import DropDownListSelectCustom from '../../MasterComponents/DropDownList/DropDownListSelectCustom';
import getPlacesProps, { getStatesProvinces } from '../../MasterComponents/Helper/getPlaces';
import { getText } from '../../MasterComponents/Helper/getHelper';
import IconEdit from '../../MasterComponents/Icons/IconEdit';
import config from '../../config/config';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import AlertModal from '../../MasterComponents/AlertModal.js/AlertModal';


class DashboardAddNewLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: null,
      postcode: '',
      indexOfLocation: 1,
      showAddressDetails: false,
      addressSearch: '',
      nicknameInvalid: false
    };
    this.getPlacesProps = getPlacesProps.bind(this);
  }
  inputHandler = (value, name, e) => {
    this.setState({
      [name] : value
    }, () => {
      const { address, city, postcode, state } = this.state;
      this.setState({
        addressSearch: address + (city ? ', '+city : '') + (state ? ', '+state.value : '') + (postcode ? ' '+postcode : '')
      });
    });
  }
  componentDidMount = () => {
    const { dispatch, locations, match, refresh, isEdit } = this.props;
    const section= {
      button: 'Rebates Portal',
      title: match.params ? 'Edit Location' : 'Add New Location'
    };
    dispatch(changePage(section));
    if (locations.length === 0) {
      dispatch(spinner(true));
      refresh(() => {
        dispatch(spinner(false));
        if (isEdit) {
          this.loadEditData();
        }
      });
    } else {
      if (isEdit) {
        this.loadEditData();
      }
    }
  }
  loadEditData = () => {
    const { locations, match } = this.props;
    const { id } = match.params;
    const thisLocation = locations.filter(loc => loc.id === parseInt(id))[0];
    if (thisLocation) {
      const { nickname, phoneNumber, address, city, state, postcode } = thisLocation;
      this.setState({
        nickname: nickname ? nickname : '',
        phoneNumber: phoneNumber ? phoneNumber : '',
        address: address ? address : '',
        city: city ? city : '',
        postcode: postcode ? numeral(postcode).format('00000') : '',
        state: this.getUsStates().filter( st => st.value === state )[0],
        indexOfLocation: locations.indexOf(thisLocation)+1,
        addressSearch: address + (city ? ', '+city : '') + (state ? ', '+state : '') + (postcode ? ' '+postcode : '')
      });
    }
  }
  clickCancel = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }
  handleAddressSelect = (value, placeId) => {
    if (placeId) {
      SignUpApi.getPlaceData(placeId).then(({data}) => {
        const { address_components } = data.data.result;
        const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0] || address_components.filter(a => a.types[0] === 'premise')[0];
        const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
        const zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];
        this.setState({
          addressSearch: (`${streetNumber ? streetNumber.long_name + ' ' : ''}${street ? street.long_name : ''}`) + (city ? `, ${city.long_name}` : '') + (usState ? `, ${usState.short_name}` : '') + (zipcode ? ` ${zipcode.long_name.toString().trim()}` : ''),
          address: (`${streetNumber ? streetNumber.long_name : ''}${street ? ' '+street.long_name : ''}`),
          city: city ? city.long_name : '',
          postcode: zipcode ? zipcode.long_name.toString().trim() : '',
          state: {
            label: usState ? usState.long_name : '',
            value: usState ? usState.short_name : ''
          },
          showAddressDetails: !(streetNumber && street && city && usState && zipcode) ? true : this.state.showAddressDetails
        });
      }).catch(err => {
        console.error(err);
      });
    } else {
      this.setState({
        nickname: value,
        showAddressDetails: true
      });
    }
  };
  handleNicknameSelect = (value, placeId) => {
    if (placeId) {
      SignUpApi.getPlaceData(placeId).then(({data}) => {
        const { address_components } = data.data.result;
        const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0];
        const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
        const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
        const zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];
        this.setState({
          phoneNumber: data.data.result.formatted_phone_number || this.state.phoneNumber,
          nickname: data.data.result.name,
          addressSearch: (`${streetNumber ? streetNumber.long_name + ' ' : ''}${street ? street.long_name : ''}`) + (city ? `, ${city.long_name}` : '') + (usState ? `, ${usState.short_name}` : '') + (zipcode ? ` ${zipcode.long_name.toString().trim()}` : ''),
          address: (`${streetNumber ? streetNumber.long_name : ''}${street ? ' '+street.long_name : ''}`),
          city: city ? city.long_name : '',
          postcode: zipcode ? zipcode.long_name.toString().trim() : '',
          state: {
            label: usState ? usState.long_name : '',
            value: usState ? usState.short_name : ''
          },
          showAddressDetails: !(streetNumber && street && city && usState && zipcode) ? true : this.state.showAddressDetails
        });
      }).catch(err => {
        console.error(err);
      });
    } else {
      this.setState({
        nickname: value,
        showAddressDetails: true
      });
    }
  };
  setNicknameSearch = (value) => {
    this.setState({
      nickname: value
    });
  };
  setAddressSearch = (value) => {
    this.setState({
      addressSearch: value
    });
  };
  toggleAddressDetails = () => {
    this.setState({
      showAddressDetails: !this.state.showAddressDetails
    });
  };
  clearAddressSearch = (value) => {
    this.setState({
      addressSearch: ''
    });
  };
  clickSave = () => {
    const { pnInput, laInput, cInput, zcInput, state } = this.refs;
    const { nickname } = this.state;
    const { dispatch, history, match, companyId, refresh, country, isEdit } = this.props;
    if (nickname.length < 4) {
      this.setState({
        nicknameInvalid: true
      });
    }
    const n = !(nickname.length < 4);
    if (n && pnInput.isValid() && laInput.isValid() && cInput.isValid() && state.isValid() && zcInput.isValid()) {
      const { state, ...fields} = this.state;
      const street = [{
        address: fields.address,
        city: fields.city,
        state: state.value,
        postcode: fields.postcode
      }];
      dispatch(spinner(true));
      SignUpApi.getSmartyPlace(street, country).then(({data}) => {
        dispatch(spinner(false));
        const smartStreet = data.data[0];
        const params = {
          ...fields,
          ...smartStreet,
          postcode: smartStreet.postcode.toString().trim(),
          companyId: companyId,
        };
        dispatch(spinner(true));
        if (isEdit) {
          const { id } = match.params;
          ReactGA.event({
            category: 'Edit Location',
            action: 'Click Save'
          });
          DashboardApi.editMerchant(parseInt(id), params).then(({data}) => {
            dispatch(spinner(false));
            refresh();
            history.push('/dashboard/rebates/card');
          }).catch(err => {
            console.error(err);
            dispatch(spinner(false));
          });
        } else {
          DashboardApi.addMerchant(params).then(({data}) => {
            dispatch(spinner(false));
            refresh();
            history.push('/dashboard/rebates/card');
          }).catch(err => {
            console.error(err);
            const message = err.data.data.message || 'Error, Request could not be sent';
            const props = {
              message : message,
              buttonText: 'Ok'
            }
            dispatch(spinner(false));
            dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
          });
        }
      }).catch(err => {
        console.error(err);
        dispatch(spinner(false));
      });
    }
  }
  selectState = (value) => {
    this.setState({
      state: value
    }, () => {
      const { address, city, postcode, state } = this.state;
      this.setState({
        addressSearch: address + (city ? ', '+city : '') + (state ? ', '+state.value : '') + (postcode ? ' '+postcode : '')
      });
    });
  }
  getUsStates = () => {
    return getStatesProvinces(this.props.country);
  };
  render() {
    const {
      nickname, address, phoneNumber, city, state,
      postcode, indexOfLocation, addressSearch, showAddressDetails } = this.state;
    const { locations, country, isEdit } = this.props;
    const { handleNicknameSelect, inputHandler: inputHander, handleAddressSelect, selectState, toggleAddressDetails } = this;
    const isAnalyst = localStorage.getItem('authKeyAnalyst');
    return (
      <GridRow className={isMobile ? 'py-3' : 'px-4 py-4'}>
        <Card className={ isMobile ? '' : 'col-8'}>
          <GridRow className='pb-0'>
            <GridCol className='text-size-16 font-weight-bold'>
              Location { isEdit ? indexOfLocation : locations.length + 1}
            </GridCol>
            <GridCol className='text-right'>
              <img className='w-100' style={{maxWidth: '100px'}} src={byGoogle} alt='google' />
            </GridCol>
          </GridRow>
          <GridRow className='pb-0'>
            <GridCol size={isMobile ? 12 : 6}>
              <InputLabel text='Location Name *' />
              {
                showAddressDetails ?
                  <Input placeholder='Business Name' validate onChange={inputHander} value={nickname} name='nickname' />
                :
                  <DropDownListSelectCustom {...this.getPlacesProps(country, 'optionsPlace', nickname, handleNicknameSelect)} />
              }
            </GridCol>
            <GridCol size={isMobile ? 12 : 6} className={isMobile ? 'mt-3' : ''}>
              <InputLabel text='Phone Number *' />
              <Input ref='pnInput' placeholder='Phone Number' validate onChange={inputHander} value={phoneNumber} name='phoneNumber' type='tel' />
            </GridCol>
          </GridRow>
          <GridRow className='pb-0'>
            <GridCol size={12}>
              <InputLabel text='Location Address *'/>
            </GridCol>
          </GridRow>
          <GridRow className='pt-0 pb-0'>
            <GridCol>
              <DropDownListSelectCustom {...this.getPlacesProps(country, 'optionsPlace', addressSearch, handleAddressSelect)} />
            </GridCol>
            { isAnalyst && 
              <GridCol style={{maxWidth: 'fit-content'}}>
                <Button onClick={toggleAddressDetails} style={{width: '40px', height: '40px'}} variant={ showAddressDetails ? 'blue' : 'white'} icon={<IconEdit color={ !showAddressDetails ? config.colors.rbBlue : config.colors.rbWhite} />}/>
              </GridCol>
            }
          </GridRow>
          <GridRow className={'mx-0' + (showAddressDetails ? '' : ' d-none')}>
            <GridCol className='py-3' style={{backgroundColor: '#F8F8F8'}}>
              <GridRow>
                <GridCol>
                  <InputLabel text='Address Line *' />
                  <Input ref="laInput" type='text' name="address" value={address || ''} onChange={inputHander} validate min={3} max={200} placeholder='Address Line' />
                </GridCol>
              </GridRow>
              <GridRow className='line-group'>
                <GridCol className={(isMobile ?  'col-12': 'col-4') + ' form-group'}>
                  <InputLabel text='City *' />
                  <Input ref="cInput" type='text' name="city" value={city || ''} onChange={inputHander} validate min={3} max={100} placeholder='City' />
                </GridCol>
                <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                  <InputLabel text={getText(country, 'state')} />
                  <DropDownListSelect
                    name='state' ref='state'
                    value={state} onChange={selectState}
                    options={getStatesProvinces(country)}/>
                </GridCol>
                <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                  <InputLabel text={getText(country, 'zipCode')} />
                  <Input ref="zcInput" type='zipCode' name="postcode" value={postcode || ''} onChange={inputHander} country={country} validate placeholder={getText(country, 'zipCode')} />
                </GridCol>
              </GridRow>
            </GridCol>
          </GridRow>
          <GridRow className='mt-2'>
            <GridCol>
              <GridRow>
                <GridCol>
                  <Button size='lg' text='Cancel' onClick={this.clickCancel} className='w-100' variant='white' />
                </GridCol>
                <GridCol>
                  <Button size='lg' text='Save' onClick={this.clickSave} className='w-100' variant='primary' />
                </GridCol>
              </GridRow>
            </GridCol>
          </GridRow>
        </Card>
      </GridRow>
    );
  }
};

export default DashboardAddNewLocation;