import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config/config';
import AlertModal from '../../../MasterComponents/AlertModal.js/AlertModal';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import LOTAcknowledgementModal from '../../SignUp/Modals/LOTAcknowledgementModal';
import SignUpApi from '../../SignUp/SignUpApi';
import usePlacesForm from '../../SignUpSimple/hooks/usePlacesForm';
import Joi from 'joi';

const SCHEMA = Joi.object({
  firstName: Joi.string().required().min(2),
  lastName: Joi.string().required().min(2),
  title: Joi.string().required(),
  restaurantName: Joi.string().required().min(2),
  phone: Joi.string().length(14),
  email: Joi.string().email({ tlds: {allow: false} }).required().label('Email'),
	state: Joi.string().required().length(2),
	addressLine: Joi.string().required().min(3),
	city: Joi.string().required().min(2),
	zipcode: Joi.string().required().length(5),
  password: Joi.string().required().min(6),
  repeatPassword: Joi.string().valid(Joi.ref('password')).messages({'any.only': `Passwords don't match`}),
  isGpo: Joi.boolean(),
  gpoName: Joi.string().when('isGpo', {is: false, then: Joi.allow('')}).when('isGpo', {is: true, then: Joi.string().required()}),
  terminationDate: Joi.date(),
  isDma: Joi.boolean(),
  nameOfCompanies: Joi.array().when('isDma', {is: true, then: Joi.array().min(1)}),
  termsAndConditions: Joi.bool().invalid(false).messages({'any.invalid':'Please check Terms And Conditions'}),
  purchaseFromDistributor: Joi.string().required(),
});

const INITIAL_STATE = {
	sfLeadId: '',
	showFullForm: false,
	showSucceed: false,
	firstName: '',
	lastName: '',
	title: null,
	restaurantName: '',
	phone: '',
	email: '',
	password: '',
	repeatPassword: '',
	reportingLocations: 1,
	isGpo: false,
	customGpoName: '',
	gpoName: '',
	terminationDate: new Date(),
	isDma: false,
	nameOfCompanies: {
		manufacturers: [],
		search: '',
		value: []
	},
	termsAndConditions: false,
	state: null,
	addressLine: '',
	city: '',
	zipCode: '',
	showAddressDetails: false,
	invalid: [],
	purchaseFromDistributor: null
};

const ACTIONS = {
	FIELD: 'FIELD',
	NAME_OF_COMPANIES: 'NAME_OF_COMPANIES',
	ACKNOWLEDGED_GPO: 'ACKNOWLEDGED_GPO',
	MULTIPLE: 'MULTIPLE',
	INVALID: 'INVALID'
};

const reducer = (state, {type, payload}) => {
	switch (type) {
		case ACTIONS.INVALID:
			return {
				...state,
				invalid: payload,
				showAddressDetails: payload.some(i => i === 'state' || i === 'addressLine' || i === 'city' || i === 'zipCode' || i === 'phone') ? true : state.showAddressDetails
			};
		case ACTIONS.MULTIPLE:
			return {
				...state,
				...payload
			};
		case ACTIONS.ACKNOWLEDGED_GPO:
			return {
				...state,
				isGpo: payload,
				gpoName: payload ? state.gpoName : ''
			};
		case ACTIONS.NAME_OF_COMPANIES:
			if (payload.field === 'value') {
				return {
					...state,
					nameOfCompanies: {
						...state.nameOfCompanies,
						value: payload.value.map(c => {
							if (c.isCustom) {
								return {
									...c,
									label: c.value
								}
							}
							return c;
						})
					}
				};
			}
			return {
				...state,
				nameOfCompanies: {
					...state.nameOfCompanies,
					[payload.field]: payload.value
				}
			};
		case ACTIONS.FIELD:
      if (payload.field === 'email') {
        sessionStorage.setItem('signUpEmail', payload.value);
      }
			return {
				...state,
				[payload.field]: payload.value
			};
		default:
			return state;
	}
};

const useJoinSignup = (history, scrollTop) => {
	const dispatch = useDispatch();
	const [state, dispatchForm] = useReducer(reducer, INITIAL_STATE);
	const placesForm = usePlacesForm('us');
  const company = useSelector(({company}) => company);
  const [signatureInput, setSignatureInput] = useState('');

	const onCreateAccount = () => {
		const values = {
			firstName: state.firstName,
			lastName: state.lastName,
			title: state.title && state.title.value,
			restaurantName: state.restaurantName,
			phone: state.phone,
			email: state.email,
			state: state.state && state.state.value,
			addressLine: state.addressLine,
			city: state.city,
			zipcode: state.zipcode,
			password: state.password,
			repeatPassword: state.repeatPassword,
			isGpo: state.isGpo,
			gpoName: state.gpoName,
			terminationDate: state.terminationDate,
			isDma: state.isDma,
			nameOfCompanies: state.nameOfCompanies.value,
			termsAndConditions: state.termsAndConditions,
			purchaseFromDistributor: state.purchaseFromDistributor && state.purchaseFromDistributor.value,
		}
		const validation = SCHEMA.validate(values, {abortEarly: false, errors: {wrap: {label: ''}}});
		dispatchForm({
			type: ACTIONS.INVALID,
			payload: validation.error ? validation.error.details.map(e => e.context.key) : []
		});

		if (!validation.error) {
			smartifyAddress();
		}
	};

	const smartifyAddress = () => {
    const streets = [{
      address: state.addressLine,
      city: state.city,
      state: state.state.value,
      postcode: state.zipcode
    }];
    dispatch(spinner(true));
    SignUpApi.getSmartyPlace(streets, 'us').then(({data}) => {
      dispatch(spinner(false));
      const smartyLocations = data.data.map(loc => ({
        ...loc,
        postcode: loc.postcode.toString().trim()
      }));
      createAccount(smartyLocations);
    }).catch(err => {
      dispatch(spinner(false));
			console.error(err);
			const message = (err && err.data && err.data.data && err.data.data.message) || `Something wen't wrong`;
			dispatch(showModal(AlertModal, 'idAlertModal', { message }))
    });
	};

	const createAccount = (smartyLocations) => {
    const params = {
			contactName: state.firstName + ':' + state.lastName,
      title: state.title.value,
      businessName: state.restaurantName,
      businessAddress: smartyLocations[0].address,
      businessCity: smartyLocations[0].city,
      businessState: smartyLocations[0].state,
      businessPostcode: smartyLocations[0].postcode,
      businessStateName: state.state.label,
      phoneNumber: state.phone,
      email: state.email,
      password: state.password,
      passwordRepeat: state.repeatPassword,
      emailRepeat: state.email,
			referredBy: process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_ID !== undefined ? process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_ID : 'CP-002739',
			referredName: process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_DESCRIPTION !== undefined ? process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_DESCRIPTION : 'Marketing User',
			daSalesRep: 'Inside Sales',
			siteUrl: `https://${window.location.hostname}/`,
			siteFullUrl: window.location.href,
      companyId: company.id,
      brandCode: company.companyBrand.code,
      brand: company.companyBrand.name,
			totalReportingLocations: parseInt(state.reportingLocations.value),
			sfLeadId: state.sfLeadId,
      lop: {
        isReinhartAgreement: 0,
        isDistributorManufacturerAgreement: state.isDma ? 1 : 0,
        legalName: state.restaurantName,
        signedText: state.firstName + ' ' + state.lastName,
        signature: signatureInput,
        isFsr: 1,
        isGpo: state.isGpo ? 1 : 0,
        gpoName: state.isGpo ? (state.gpoName || '') : '',
        terminationDate: state.terminationDate,
        nameOfCompanies: state.isDma ? state.nameOfCompanies.value.map(c => {
          return c.nickname;
        }).toString() : '',
        manufacturers: state.isDma ? state.nameOfCompanies.value.map(item => {
          return item.id
        }) : []
      },
	  purchaseFromDistributor: state.purchaseFromDistributor.value,
      segment: company.segment,
      subsegment: company.subsegment
    };
    dispatch(spinner(true));
    SignUpApi.signupSimple(params).then(({data}) => {
			dispatch(spinner(false));
			dispatchForm({
				type: ACTIONS.FIELD,
				payload: {
					field: 'showSucceed',
					value: true
				}
			});
			history.push({
				pathname: '/join-dining-alliance-for-free-today',
				search: '?thankyou=1'
			});
			scrollTop();
    }).catch(err => {
      dispatch(spinner(false));
			console.error(err);
			const message = (err && err.data && err.data.data && err.data.data.message) || `Something wen't wrong`;
			dispatch(showModal(AlertModal, 'idAlertModal', { message }))
    });
  };

	const onChange = (e) => {
		dispatchForm({
			type: ACTIONS.FIELD,
			payload: {
				field: e.target.name,
				value: e.target.value
			}
		});
	};

	useEffect(() => {
		searchManufacturers('', 1);
		// eslint-disable-next-line
	}, []);

	const searchManufacturers = (search, page) => {
		dispatch(spinner(true));
    SignUpApi.getManufacturers(search, page).then(({data}) => {
      dispatch(spinner(false));
      dispatchForm({
				type: ACTIONS.NAME_OF_COMPANIES,
				payload: {
					field: 'manufacturers',
					value: data.data.map(m => {
						return {
							...m,
							label: m.nickname,
							value: m.id
						};
					})
				}
			});
    })
	};

	const onPlacesChange = ({label, value: place_id}, firstOne) => {
		if (place_id) {
			SignUpApi.getPlaceData(place_id).then(({data}) => {
				const { result } = data.data;
				const { address_components, name, formatted_phone_number } = result;
				const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0] || address_components.filter(a => a.types[0] === 'premise')[0];
				const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
				const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
				const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
				const zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];
				dispatchForm({
					type: ACTIONS.MULTIPLE,
					payload: {
						addressLine: (`${(streetNumber && streetNumber.long_name) || ''}${street ? ' '+street.long_name : ''}`),
						state: usState ? {
							label: (usState && usState.long_name) || '',
							value: (usState && usState.short_name) || ''
						} : null,
						city: (city && city.long_name) || '',
						zipcode: (zipcode && zipcode.long_name.toString().trim()) || '',
						restaurantName: firstOne ? state.restaurantName : (name || label),
						phone: firstOne ? state.phone : formatted_phone_number,
						showAddressDetails: firstOne ? (state.phone !== formatted_phone_number) : (!(streetNumber && street && city && usState && zipcode) || state.showAddressDetails)
				}});
			}).catch(err => {
				console.error(err);
			})
		} else {
			dispatchForm({
				type: ACTIONS.MULTIPLE,
				payload: {
					restaurantName: label,
					showAddressDetails: true
			}});
		}
	};

	return {
		cleanSignatureHandler: () => {
			setSignatureInput('');
		},
		sendSignatureHandler: (drawing) => {
			setSignatureInput(drawing)
		},
		onCreateAccount,
		onSubmit: () => {
			const params = {
				restaurantName: state.restaurantName,
				lastName: state.lastName,
				firstName: state.firstName,
				title: state.title.value,
				phone: state.phone,
				email: state.email,
				state: state.state.value,
				purchaseFromDistributor: state.purchaseFromDistributor.value
			};
			dispatch(spinner(true));
			SignUpApi.lead(params).then(({data}) => {
				dispatch(spinner(false));
				const leadId = data.data;
				history.push({
					pathname: '/join-dining-alliance-for-free-today',
					search: '?lead=1'
				});
				SignUpApi.getPlaceSearch(`${state.restaurantName}, ${state.state.value}, USA`, 'us').then(({data}) => {
					const predictions = data.data.predictions.map(pred => {
						return {
							label: pred.description,
							value: pred.place_id
						}
					});

					onPlacesChange(predictions.length > 0 ? predictions[0] : {label: state.restaurantName}, true);
					dispatchForm({
						type: ACTIONS.MULTIPLE,
						payload: {
							sfLeadId: leadId,
							showFullForm: true
						}
					});
				}).catch(err => {
					dispatch(spinner(false));
					onPlacesChange({label: state.restaurantName}, true);
					dispatchForm({
						type: ACTIONS.MULTIPLE,
						payload: {
							sfLeadId: leadId,
							showFullForm: true
						}
					});
				});
			}).catch(err => {
				dispatch(spinner(false));
				console.error(err);
				const message = (err && err.data && err.data.data && err.data.data.message) || `Something wen't wrong`;
				dispatch(showModal(AlertModal, 'idAlertModal', { message }))
			});
		},
		showFullForm: state.showFullForm,
		showSucceed: state.showSucceed,
		showAddressDetails: state.showAddressDetails,
		toggleButton: {
			onClick: () => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'showAddressDetails',
						value: !state.showAddressDetails
					}
				})
			},
			color: 'white',
			className:`text-${state.showAddressDetails ? 'action' : 'white'} rounded-0`,
			outline: !state.showAddressDetails
		},
		form: state,
		firstName: {
			value: state.firstName,
			name: 'firstName',
			onChange,
		},
		lastName: {
			value: state.lastName,
			name: 'lastName',
			onChange,
		},
		title: {
			value: state.title,
			name: 'title',
			options: config.signUpStepOneTitleOptions.map(t => ({label: t, value: t})),
			onChange: (t) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload:{
						field: 'title',
						value: t
					}
				});
			},
		},
		restaurantName: {
			value: state.restaurantName,
			name: 'restaurantName',
			onChange,
		},
		phone: {
			value: state.phone,
			name: 'phone',
			onChange,
		},
		email: {
			value: state.email,
			name: 'email',
			onChange,
		},
		password: {
			value: state.password,
			name: 'password',
			type: 'password',
			onChange,
		},
		repeatPassword: {
			value: state.repeatPassword,
			name: 'repeatPassword',
			type: 'password',
			onChange,
		},
		placesAutocomplete: {
			placeholder: 'Search Business Name...',
			name: 'googlePlaces',
			blurInputOnSelect: true,
			openMenuOnClick: false,
			filterOption: false,
			onInputChange: 3,
			components: { DropdownIndicator:() => null, IndicatorSeparator:() => null },
			menuIsOpen: false,
			value: state.restaurantName ? {label: state.restaurantName, value: state.restaurantName} : '',
			onSearch: (string) => {
				placesForm.onSearch(string);
			},
			options: placesForm.predictions,
			onChange: (opt) => onPlacesChange(opt, false)
		},
		addressLine: {
			value: state.addressLine,
			name: 'addressLine',
			onChange,
		},
		city: {
			value: state.city,
			name: 'city',
			onChange,
		},
		zipcode: {
			value: state.zipcode,
			name: 'zipcode',
			onChange,
		},
		reportingLocations: {
			value: {label: state.reportingLocations, value: state.reportingLocations},
			name: 'reportingLocations',
			isSearchable: false,
			options: [1,2,3,4,5,6,7,8,9,10].map(n => ({label: n, value: n})).filter(o => o.value !== state.reportingLocations),
			onChange: (n) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'reportingLocations',
						value: n.value
					}
				});
			},
		},
		isGpo: {
			value: {label: state.isGpo ? 'Yes' : 'No', value: state.isGpo},
			name: 'isGpo',
			isSearchable: false,
			options: [{value: true,label: 'Yes'}, {value: false,label: 'No'}].filter(o => o.value !== state.isGpo),
			onChange: (n) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'isGpo',
						value: n.value
					}
				});
			},
		},
		terminationDate: {
			selected: state.terminationDate,
			locale: 'en',
			popperPlacement: "bottom-end",
			dateFormat: 'MM/dd/yyyy',
			onChange: (date) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'terminationDate',
						value: date
					}
				});
			}
		},
		gpoName: {
			value: state.gpoName ? {label: state.gpoName, value: state.gpoName} : null,
			name: 'gpoName',
			options: [{label: `Add: ${state.customGpoName || '<Type to add custom name>'}`, value: state.customGpoName}, ...config.gpoNameOptions],
			isSearchable: true,
			onInputChange: (text) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'customGpoName',
						value: text
					}
				});
			},
			onChange: (option) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'gpoName',
						value: option.value
					}
				});
				dispatch(showModal(LOTAcknowledgementModal, 'LOTAcknowledgementModal', {
					message: config.LOTLegalText(option.value, state.restaurantName),
					acknowledgeLOTTerms: (bool) => {
						dispatchForm({
							type: ACTIONS.ACKNOWLEDGED_GPO,
							payload: bool
						});
					}
				}))
			},
		},
		isDma: {
			value: {label: state.isDma ? 'Yes' : 'No', value: state.isDma},
			name: 'isDma',
			isSearchable: false,
			options: [{value: true,label: 'Yes'}, {value: false,label: 'No'}].filter(o => o.value !== state.isDma),
			onChange: (n) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'isDma',
						value: n.value
					}
				});
			},
		},
		nameOfCompanies: {
			onChange: (value) => {
				dispatchForm({
					type: ACTIONS.NAME_OF_COMPANIES,
					payload: {
						field: 'value',
						value: value
					}
				});
			},
			value: state.nameOfCompanies.value,
			isMulti: true,
			options: state.nameOfCompanies.search === '' ? state.nameOfCompanies.manufacturers : [{label: 'Add: '+state.nameOfCompanies.search, nickname: state.nameOfCompanies.search, value: state.nameOfCompanies.search, isCustom: true}, ...state.nameOfCompanies.manufacturers],
			onInputChange: (text) => {
				dispatchForm({
					type: ACTIONS.NAME_OF_COMPANIES,
					payload: {
						field: 'search',
						value: text
					}
				});
				searchManufacturers(text, 1);
			}
		},
		state: {
			value: state.state,
			name: 'state',
			options: config.usStates.map(s => ({label: s.name, value: s.abbreviation})),
			onChange: (s) => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'state',
						value: s
					}
				});
			},
		},
		termsAndConditions: {
			checked: state.termsAndConditions,
			onChange: () => {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'termsAndConditions',
						value: !state.termsAndConditions
					}
				});
			},
			className: `custom-control-input`
		},
		purchaseFromDistributor: {
			value: state.purchaseFromDistributor,
			name: 'purchaseFromDistributor',
			options: [
				{ value: 'Yes', label: 'Yes' },
				{ value: 'No', label: 'No' },
				{ value: 'I don’t know', label: 'I don’t know' },
			],
			onChange: (o)=> {
				dispatchForm({
					type: ACTIONS.FIELD,
					payload: {
						field: 'purchaseFromDistributor',
						value: o
					}
				});
			}
		}
	}
};

export default useJoinSignup;
