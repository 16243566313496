export const companyWebsites = [
  {
    'development': 'https://dev-cashback.diningalliance.com',
    'staging': 'https://staging-cashback.diningalliance.com',
    'production': 'https://my.diningalliance.com',
  },
  {
    'development': 'https://dev-cashback.diningalliance.com',
    'staging': 'https://staging-cashback.diningalliance.com',
    'production': 'https://cashback.diningalliance.com',
  },
  {
    'development': 'https://dev.mydvpg.com',
    'staging': 'https://staging.mydvpg.com',
    'production': 'https://www.mydvpg.com',
  },
  {
    'development': 'https://portal-dev.consolidatedconcepts.net',
    'staging': 'https://portal-staging.consolidatedconcepts.net',
    'production': 'https://portal.consolidatedconcepts.net',
  },
  {
    'development': 'https://dev-rebates.source1purchasing.com',
    'staging': 'https://staging-rebates.source1purchasing.com',
    'production': 'https://rebates.source1purchasing.com',
  },
  {
    'development': 'https://dev.mysouthstar.com',
    'staging': 'https://staging.mysouthstar.com',
    'production': 'https://mysouthstar.com',
  },
  {
    'development': 'https://dev.myngcra.com',
    'staging': 'https://staging.myngcra.com',
    'production': 'https://myngcra.com',
  },
  {
    'development': 'https://dev.restaurantrebatehub.com',
    'staging': 'https://staging.restaurantrebatehub.com',
    'production': 'https://restaurantrebatehub.com',
  },
  {
    'development': 'https://dev.myalliancepurchasing.com',
    'staging': 'https://staging.myalliancepurchasing.com',
    'production': 'https://myalliancepurchasing.com',
  },
  {
    'development': 'https://dev.myradisson-marketplace.com',
    'staging': 'https://staging.myradisson-marketplace.com',
    'production': 'https://myradisson-marketplace.com',
  },
  {
    'development': 'https://dev.mybestwestern-marketplace.com',
    'staging': 'https://staging.mybestwestern-marketplace.com',
    'production': 'https://mybestwestern-marketplace.com',
  },
  {
    'development': 'https://dev.mypurchasesmaart.com',
    'staging': 'https://staging.mypurchasesmaart.com',
    'production': 'https://mypurchasesmaart.com',
  },
  {
    'development': 'https://dev.myrbgportal.com',
    'staging': 'https://staging.myrbgportal.com',
    'production': 'https://myrbgportal.com',
  },
  {
    'development': 'https://dev.valethospitality.com',
    'staging': 'https://staging.valethospitality.com',
    'production': 'https://valethospitality.com ',
  },
  {
    'development': 'https://dev.mysmartrebates.com',
    'staging': 'https://staging.mysmartrebates.com',
    'production': 'https://mysmartrebates.com',
  },
  {
    'development': 'https://dev.primeservicesportal.com',
    'staging': 'https://staging.primeservicesportal.com',
    'production': 'https://primeservicesportal.com',
  },
  {
    'development': 'https://dev.beportal.restacct.com',
    'staging': 'https://staging.beportal.restacct.com',
    'production': 'https://beportal.restacct.com ',
  },
]
