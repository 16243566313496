import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import Button from '../../../../MasterComponents/Button/Button';
import Input from '../../../../MasterComponents/Input/Input';
import DropDownList from '../../../../MasterComponents/DropDownList/DropDownList';
import DropDownListOption from '../../../../MasterComponents/DropDownList/DropDownListOption';
import DropDownListSelectCustom from '../../../../MasterComponents/DropDownList/DropDownListSelectCustom';
import IconInformation from '../../../../MasterComponents/Icons/IconInformation';
import { getIconInformationColor } from '../../../../config/Company/CompanyHelper';
import $ from 'jquery';

class FormExistingUserStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onFocus: false
        };
    };
    componentDidMount = () => {
        $('[data-toggle="toolTipExistingUserSalesRep"]').tooltip();
        $('[data-toggle="toolTipExistingUserReferredBy"]').tooltip();
    }
    isValid = () => {
        const { setError } = this.props;
        const { platformId, businessName, firstName, lastName, title, email, password, repeatPassword, daSalesRep, referredBy} = this.refs;
        const pid = platformId.isValid();
        const bn = businessName.isValid();
        const fn = firstName.isValid();
        const ln = lastName.isValid();
        const t = title.isValid();
        const e = email.isValid();
        const pwd = password.isValid();
        const rpwd = repeatPassword.isValid();
        const sr = daSalesRep ? daSalesRep.isValid() : false;
        const rb = referredBy.isValid();
        if ((pid && bn && fn && ln && t && e && pwd && rpwd)) {
            if (password.props.value !== repeatPassword.props.value) {
                password.setInvalid();
                repeatPassword.setInvalid();
                setError("Passwords don't match.");
                return false;
            }
            if(sr || rb) {
                if (daSalesRep && daSalesRep.props.value) return true;
                if (referredBy.props.value) return true;
                return false;
            }
        }
        setError('Required fields missing.');
        return false;
    };

    getSalesRepresentativeList = () => {
        const {daSalesRepFromApi} = this.props;
        const options = daSalesRepFromApi.map((item) => {
            return {
                label: item.name,
                value: item.id,
                platformId: item.platformId,
                isDaSalesRep: item.isDaSalesRep
            }
        });
        return options;
    }

    selectSalesRepresentative = (value) => {
        this.props.selectDASalesRep(value);
    }

    getReferredList = () => {
        const {referredPartnerFromApi} = this.props;
        const options = referredPartnerFromApi.map((item) => {
            return {
                label: item.name,
                value: item.id,
                platformId: item.platformId,
                isDaSalesRep: item.salesRepId
            }
        });
        return options;
    }

    selectReferred = (value) => {
        this.props.selectReferredBy(value);
    }

    doneExisting = () => {
        if (this.isValid()) {
            this.props.done();
        }
    };

    render() {
        const {
            platformId, businessName, firstName, lastName, title, email, password,
            repeatPassword, changeHandler, selectTitle, exit, error, daSalesRep,
            referredBy, companyText, brandSelected, companyId, hideSalesRep, disabledRefferedBy
        } = this.props;
        const { doneExisting } = this;
        const labelSalesRep = companyId !== 4 ? companyText.existingSalesRep : 'Client Manager';
        const iconInformationColor = getIconInformationColor(companyId);
        return (
            <GridCol>
                <GridRow>
                    <GridCol>
                        <InputLabel text={companyText.contactInformationHeader} title className='title'/>
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text={companyText.existingPlatformId} />
                        <Input ref='platformId' type='text' requiredCL value={platformId} name="platformId" validateCL onChange={changeHandler} placeholder='CL-1234567' />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Location Name *'/>
                        <Input ref='businessName' type='text' value={businessName} name="businessName" validate min={3} max={100} onChange={changeHandler} placeholder='Location Name' />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='First Name *'/>
                        <Input ref='firstName' type='text' value={firstName} name="firstName" validate min={2} max={100} onChange={changeHandler} placeholder='First Name' />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Last Name *'/>
                        <Input ref='lastName' type='text' value={lastName} name="lastName" validate min={2} max={100} onChange={changeHandler} placeholder='Last Name' />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Title *'/>
                        <DropDownList ref='title' title={title}>
                            <DropDownListOption title='Owner' onClick={selectTitle} />
                            <DropDownListOption title='Chef' onClick={selectTitle} />
                            <DropDownListOption title='General Manager' onClick={selectTitle} />
                            <DropDownListOption title='Kitchen Manager' onClick={selectTitle} />
                            <DropDownListOption title='CFO' onClick={selectTitle} />
                            <DropDownListOption title='COO' onClick={selectTitle} />
                            <DropDownListOption title='Accounting' onClick={selectTitle} />
                            <DropDownListOption title='CEO/President' onClick={selectTitle} />
                            <DropDownListOption title='Vice President' onClick={selectTitle} />
                            <DropDownListOption title='Food Service Director' onClick={selectTitle} />
                            <DropDownListOption title='VP of Purchasing/Supply Chain' onClick={selectTitle} />
                            <DropDownListOption title='Other' onClick={selectTitle} />
                        </DropDownList>
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Email *'/>
                        <Input ref='email' type='email' value={email} validate name="email" onChange={changeHandler} placeholder='Email' />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Create Password *'/>
                        <Input ref='password' type='password' value={password} name='password' validate min={6} max={50} onChange={changeHandler} placeholder='******' />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Confirm Password *'/>
                        <Input ref='repeatPassword' type='password' value={repeatPassword} name='repeatPassword' validate min={6} max={50} onChange={changeHandler} placeholder='******' />
                    </GridCol>
                </GridRow>
                {
                    (hideSalesRep === false || hideSalesRep === undefined || hideSalesRep === null) &&
                    <GridRow className='line-form'>
                        <GridCol className='form-group' size={isMobile ? 12 : 6}>
                            <InputLabel text={labelSalesRep}/> &nbsp;
                            <span className='clickable' data-toggle='toolTipExistingUserSalesRep' title={`This is your ${brandSelected.name} ${labelSalesRep}. If you don't know, you can select "I don't know my ${labelSalesRep}."`}>
                                <IconInformation color={iconInformationColor} size={13} />
                            </span>
                            <DropDownListSelectCustom
                                name='daSalesRep' ref='daSalesRep'
                                value={daSalesRep} onChange={this.selectSalesRepresentative}
                                options={this.getSalesRepresentativeList()}
                                blurInputOnSelect={true}
                                openMenuOnClick={false}
                                onInputChange={3}
                                menuIsOpen = {false}
                            />
                        </GridCol>
                    </GridRow>
                }
                <GridRow className='line-form'>
                    <GridCol className='form-group' size={isMobile ? 12 : 6}>
                        <InputLabel text='Channel Partner'/> &nbsp;
                        <span className='clickable' data-toggle='toolTipExistingUserReferredBy' title={`Populate this field if you were referred here by anyone besides a ${brandSelected.name} rep. If not, you can leave this field blank.`}>
                            <IconInformation color={iconInformationColor} size={13} />
                        </span>
                        <DropDownListSelectCustom
                            name='referredBy' ref='referredBy'
                            value={referredBy} onChange={this.selectReferred}
                            options={this.getReferredList()}
                            blurInputOnSelect={true}
                            openMenuOnClick={false}
                            onInputChange={3}
                            isDisabled={referredBy.lock || disabledRefferedBy}
                            menuIsOpen = {false}
                        />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form mt-3 flex-row'>
                    <GridCol size={isMobile ? 6 : 4}>
                        <Button type='button' variant='white' text='BACK'  onClick={exit} />
                    </GridCol>
                    <GridCol size={isMobile ? 6 : 4}>
                        <Button type='button' variant='primary' text='DONE'  onClick={doneExisting} />
                    </GridCol>
                </GridRow>
                <GridRow className='line-form mt-2'>
                    <GridCol className='form-group align-items-center'>
                        <InputLabel className='text-red text-size-14'>{error}</InputLabel>
                    </GridCol>
                </GridRow>
            </GridCol>
        )
    }
}

export default FormExistingUserStep1;
