import React, { Component } from 'react';
import { connect } from 'react-redux';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import loadingGif from '../../assets/images/opportunities-loader.gif';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import InsightsBodyOpportunitiesSampleModal from './Modals/InsightsBodyOpportunitiesSampleModal';
import InsightsBodyOpportunitiesOrderModal from './Modals/InsightsBodyOpportunitiesOrderModal';
import InsightsBodyOpportunitiesDeleteModal from './Modals/InsightsBodyOpportunitiesDeleteModal';
import { IoIosArrowUp, IoIosArrowDown, IoIosPin } from 'react-icons/io'
import config from '../../config/config';
import { Button } from 'reactstrap';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { getInsightsCurrentStyle, getInsightsSwitchIconColor, getInsightsSwitchTextColor, getInsightsSwitchStyle, getInsightsSwitchBtnShowStyle, getInsightsSwitchBtnHideStyle } from '../../config/Company/CompanyHelper';
import IconDelete from '../../MasterComponents/Icons/IconDelete';
import  './Insights.scss';
import Erni from '../../MasterComponents/Erni/Erni';

class InsightsBodyShowOpportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: 5,
      openMatches: []
    };
  };
  toggleMatch = (pId) => {
    const { openMatches } = this.state;
    this.setState({
      openMatches: openMatches.some(m => m === pId) ? openMatches.filter(m => m !== pId) : [...openMatches, pId]
    });
  };
  showMore = () => {
    this.setState({
      showing: this.state.showing + 5
    });
  };
  showLess = () => {
    this.setState({
      showing: this.state.showing - 5
    });
  };
  openSample = (item) => {
    const { dispatch, platformIds } = this.props;
    this.props.dispatch(showModal(InsightsBodyOpportunitiesSampleModal, 'InsightsBodyOpportunitiesSampleModal', {...item, dispatch, platformIds}));
  };
  openOrder = (item) => {
    const { dispatch, platformIds } = this.props;
    this.props.dispatch(showModal(InsightsBodyOpportunitiesOrderModal, 'InsightsBodyOpportunitiesOrderModal', {...item, dispatch, platformIds}));
  };
  openDelete = (item) => {
    this.props.dispatch(showModal(InsightsBodyOpportunitiesDeleteModal, 'InsightsBodyOpportunitiesDeleteModal', {
      ...item,
      dispatch: this.props.dispatch,
      refresh: this.props.refresh,
      datePeriod: this.props.datePeriod
    }));
  };
  render() {
    const { dataItemsAll, loading, companyId, userCurrent } = this.props;
    const { showing, openMatches } = this.state;
    const currentStyle = getInsightsCurrentStyle(companyId);
    const switchStyle = getInsightsSwitchStyle(companyId);
    const iconColor = getInsightsSwitchIconColor(companyId);
    const iconText = getInsightsSwitchTextColor(companyId);
    const btnShowStyle = getInsightsSwitchBtnShowStyle(companyId);
    const btnHideStyle = getInsightsSwitchBtnHideStyle(companyId);
    const hasPackSize = (companyId === 1 || companyId === 3 || companyId === 4) ? true : false;
    let displayDeviations = userCurrent.displayDeviations || null;

    const uniqueOriginalProductIds = dataItemsAll.map(d => d.original.productId).filter((v, i, a) => a.indexOf(v) === i);

    const matches = uniqueOriginalProductIds.map(pId => ({
      productId: pId,
      data: dataItemsAll.filter(d => d.original.productId === pId)
    }));

    const showShowMore = matches.length > showing;
    const showShowLess = showing > 5;

    // Column Sizes
    const sizes = {
      left: {
        main: displayDeviations ? 4 : 6,
        // Child columns
        description: hasPackSize ? 7 : 9,
        packSize: hasPackSize ? 3 : 0,
        qty: 2
      },
      right: {
        main: displayDeviations ? 8 : 6,
        // Child columns
        description: hasPackSize ? 3 : displayDeviations ? 4 : 5,
        packSize: displayDeviations ? 1 : 2,
        qty: displayDeviations ? 1 : 2,
        rebate: 1,
        deviation: 1,
        annualRebate: 5
      }
    }

    const originalsAndMatchesMap = matches.slice(0, showing).map((m, i) => (
      <GridRow key={`ori_and_matc_${i}`} className={'align-items-stretch' + (i % 2 !== 0 ? ' bg-striped' : '')}>
        <GridCol size={sizes.left.main} className='py-3 insights-line-right'>
          <GridRow className='align-items-center'>
            <GridCol size={sizes.left.description}>
              <GridRow>
                <GridCol style={{wordWrap : 'break-word'}} className='text-capitalize'>
                  {m.data[0].original.description}
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol style={{wordWrap : 'break-word'}} className='text-capitalize text-grey py-2'>
                  {m.data[0].original.mfr}
                </GridCol>
              </GridRow>
            </GridCol>
            { hasPackSize &&
              <GridCol size={sizes.left.packSize}>{m.data[0].original.packsize}</GridCol>
            }
            <GridCol size={sizes.left.qty}>{Number(m.data[0].original.qty.toFixed(2))}</GridCol>
          </GridRow>
        </GridCol>
        <GridCol size={sizes.right.main} className='py-3 align-self-center'>
          <GridRow className='align-items-center'>
            <GridCol size={sizes.right.description}>
              <GridRow>
                <GridCol style={{wordWrap : 'break-word'}} className='text-capitalize'>
                  {m.data[0].match.description}
                </GridCol>
              </GridRow>
              <GridRow>
                <GridCol style={{wordWrap : 'break-word'}} className='text-capitalize text-grey py-2'>
                  {m.data[0].match.mfr}
                </GridCol>
              </GridRow>
            </GridCol>
            { hasPackSize &&
              <GridCol size={sizes.right.packSize}>{m.data[0].match.packsize}</GridCol>
            }
            <GridCol size={sizes.right.qty}>{Number(m.data[0].match.qty.toFixed(2))}</GridCol>
            {displayDeviations && <>
              <GridCol style={{color: iconText}} size={sizes.right.rebate}>
                {m.data[0].match.rebate && '$' + m.data[0].match.rebate.toLocaleString('en', {maximumFractionDigits: 2})}
              </GridCol>
              <GridCol style={{color: iconText}} size={sizes.right.deviation}>
                {m.data[0].match.deviation && '$' + m.data[0].match.deviation.toLocaleString('en', { maximumFractionDigits: 2 })}
              </GridCol>
            </>}
            <GridCol size={sizes.right.annualRebate}>
              <GridRow className='align-items-center annual-rebate-justify text-center'>
                <GridCol style={{ color: iconText }}>
                  ${(m.data[0].match.annualRebate ? m.data[0].match.annualRebate : 0).toLocaleString('en', {minimumFractionDigits: 2})}
                </GridCol>
                <GridCol className='text-size-14 clickable' onClick={this.openOrder.bind(this, m.data[0])}>
                  <GridCol size={12}>
                    <MdOutlineShoppingCart size={18} color={iconColor} />
                  </GridCol>
                  <GridCol size={12} className='py-2' style={{color: iconColor}}>
                    Order
                  </GridCol>
                </GridCol>
                <GridCol className='text-size-14 clickable' onClick={this.openSample.bind(this, m.data[0])}>
                  <GridCol size={12} className='pl-3'>
                    <IoIosPin size={18} color={iconColor} />
                  </GridCol>
                  <GridCol size={12} className='py-2' style={{color: iconColor}}>
                    Sample
                  </GridCol>
                </GridCol>
                <GridCol className='text-size-14 clickable' onClick={this.openDelete.bind(this, m.data[0])}>
                  <GridCol size={12} className='pl-3'>
                    <IconDelete size={15} color={iconColor} />
                  </GridCol>
                  <GridCol size={12} className='py-2' style={{color: iconColor}}>
                    Delete
                  </GridCol>
                </GridCol>
              </GridRow>
            </GridCol>
          </GridRow>
          {
            m.data.length > 1 &&
            <>
              {
                openMatches.some(c => c === m.productId) &&
                m.data.slice(1).map((d, ii) => (
                  <GridRow key={`more_${i}_${ii}`} className='align-items-center border-top py-2'>
                    <GridCol size={sizes.right.description}>
                      <GridRow>
                        <GridCol style={{ wordWrap: 'break-word' }} className='text-capitalize'>
                          {d.match.description}
                        </GridCol>
                      </GridRow>
                      <GridRow>
                        <GridCol style={{ wordWrap: 'break-word' }} className='text-capitalize text-grey py-2'>
                          {d.match.mfr}
                        </GridCol>
                      </GridRow>
                    </GridCol>
                    {hasPackSize &&
                      <GridCol size={sizes.right.packSize}>{d.match.packsize}</GridCol>
                    }
                    <GridCol size={sizes.right.qty}>{Number(d.match.qty.toFixed(2))}</GridCol>
                    {displayDeviations && <>
                      <GridCol style={{color: iconText}} size={sizes.right.rebate}>
                        {d.match.rebate && '$' + d.match.rebate.toLocaleString('en', { maximumFractionDigits: 2 })}
                      </GridCol>
                      <GridCol style={{color: iconText}} size={sizes.right.deviation}>
                        {d.match.deviation && '$' + d.match.deviation.toLocaleString('en', { maximumFractionDigits: 2 })}
                      </GridCol>
                    </>}
                    <GridCol size={sizes.right.annualRebate}>
                      <GridRow className='align-items-center annual-rebate-justify text-center'>
                        <GridCol style={{color: iconText}}>
                          ${(d.match.annualRebate ? d.match.annualRebate : 0).toLocaleString('en', {minimumFractionDigits: 2})}
                        </GridCol>
                        <GridCol className='insights-show-more text-size-14 clickable' onClick={this.openOrder.bind(this, d)}>
                          <GridCol size={12} className='pl-3'>
                            <MdOutlineShoppingCart size={18} color={iconColor} />
                          </GridCol>
                          <GridCol size={12} className='py-2' style={{color: iconColor}}>
                            Order
                          </GridCol>
                        </GridCol>
                        <GridCol className='insights-show-more text-size-14 clickable' onClick={this.openSample.bind(this, d)}>
                          <GridCol size={12} className='pl-2'>
                            <IoIosPin size={18} color={iconColor} />
                          </GridCol>
                          <GridCol size={12} className='py-2' style={{ color: iconColor }}>
                            Sample
                          </GridCol>
                        </GridCol>
                        <GridCol className='insights-show-more text-size-14 clickable' onClick={this.openDelete.bind(this, d)}>
                          <GridCol size={12} className='pl-3'>
                            <IconDelete size={15} color={iconColor} />
                          </GridCol>
                          <GridCol size={12} className='py-2' style={{ color: iconColor }}>
                            Delete
                          </GridCol>
                        </GridCol>
                      </GridRow>
                    </GridCol>
                  </GridRow>
                ))
              }
              {
                openMatches.some(c => c === m.productId) ?
                  <GridRow className='pt-2'>
                    <GridCol>
                      <Button className='text-size-12 shadow-none' style={btnHideStyle} onClick={this.toggleMatch.bind(this, m.productId)}>
                        {`Hide match${(m.data.length - 1 > 1 && 'es') || ''}`} <IoIosArrowUp color={config.colors.rbWhite} fontSize='12px' />
                      </Button>
                    </GridCol>
                  </GridRow>
                  :
                  <GridRow className='pt-2'>
                    <GridCol>
                      <Button className='text-size-12 shadow-none' style={btnShowStyle} onClick={this.toggleMatch.bind(this, m.productId)}>
                        {`Show ${m.data.length - 1} more match${(m.data.length - 1 > 1 && 'es') || ''}`} <IoIosArrowDown color={config.colors.rbWhite} fontSize='12px' />
                      </Button>
                    </GridCol>
                </GridRow>
              }
            </>
          }
        </GridCol>
      </GridRow>
    ));

    return (
      <GridRow className='insights-line-bottom'>
        <GridCol className='pb-3'>
          <GridRow className={displayDeviations ? 'pl-2' : ''}>
            <GridCol size={sizes.left.main} className='pl-5 py-3 text-left insights-graph-title insights-line-bottom bg-primary text-white' style={currentStyle}>
              Currently Buying
            </GridCol>
            <GridCol size={sizes.right.main} className='pr-5 py-3 text-left insights-graph-title insights-line-bottom position-relative bg-secondary text-white' style={switchStyle}>
              Switch and Save!
              {
                !loading && matches.length > 0 && (
                  <Erni top={-75} right={75/2} message='We found similar items to those that you are purchasing today that can save you even more cashback. Click, “Order” or “Sample” to try them today.' />
                )
              }
            </GridCol>
          </GridRow>
          <GridRow className='m-0 table-font-size'>
            <GridCol>
              <GridRow className='bg-striped-title align-items-stretch'>
                <GridCol size={sizes.left.main} className='insights-line-right py-2'>
                  <GridRow className='align-items-top'>
                    <GridCol size={sizes.left.description}>DESCRIPTION</GridCol>
                    { hasPackSize &&
                      <GridCol size={sizes.left.packSize}>PACKSIZE</GridCol>
                    }
                    <GridCol size={sizes.left.qty}>QTY</GridCol>
                  </GridRow>
                </GridCol>
                <GridCol size={sizes.right.main} className='py-2'>
                  <GridRow className='align-items-top'>
                    <GridCol size={sizes.right.description}>DESCRIPTION</GridCol>
                    { hasPackSize &&
                      <GridCol size={sizes.right.packSize}>PACKSIZE</GridCol>
                    }
                    <GridCol size={sizes.right.qty}>SWITCH QTY</GridCol>
                    {displayDeviations && <>
                      <GridCol size={sizes.right.rebate}>REBATE SAVINGS</GridCol>
                      <GridCol size={sizes.right.deviation}>DEVIATION SAVINGS</GridCol>
                    </>}
                    <GridCol size={sizes.right.annualRebate}>ANNUAL REBATE</GridCol>
                  </GridRow>
                </GridCol>
              </GridRow>
              {
                loading ?
                <>
                  <GridRow>
                    <GridCol className='pb-1 text-center'>
                      <img src={loadingGif} width='150px' alt='loading' />
                    </GridCol>
                  </GridRow>
                  <GridRow className='insights-line-bottom'>
                    <GridCol className='pb-5 text-center text-size-14'>
                      Loading Opportunities...
                    </GridCol>
                  </GridRow>
                </>
                :
                originalsAndMatchesMap
              }
              <GridRow>
                {
                  showShowMore &&
                  <GridCol className='text-left insights-show-more pl-5 clickable' onClick={this.showMore}>
                    Show more <IoIosArrowDown color={config.colors.primary} fontSize='18px'/>
                  </GridCol>
                }
                {
                  showShowLess &&
                  <GridCol className='text-right insights-show-more clickable' onClick={this.showLess}>
                    Show less <IoIosArrowUp color={config.colors.primary} fontSize='18px'/>
                  </GridCol>
                }
              </GridRow>
            </GridCol>
          </GridRow>
        </GridCol>
      </GridRow>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyId: state.company.id,
    userCurrent: state.location.userCurrent
  };
}

export default connect(mapStateToProps)(InsightsBodyShowOpportunities);
