export default function reducer(state = {
  dataLookUp: [],
  dataDetail: {
    manufacturers: [],
    locations: []
  },
  currentPayment: [],
  steps: {
    step1: {
      status: true,
      done: false
    },
    step2: {
      status: false,
      done: false
    },
    step3: {
      status: false,
      done: false
    }
  },
  step1Input: {
    merchant: '',
    phoneNumer: '',
    code: ''
  },
  step3Input: {
    contactName: '',
    email: '',
    address: '',
    city: '',
    state: '',
    stateObj: null,
    countryObj: null,
    postcode: '',
    bank: '',
    bankDisabled: false,
    branch: '',
    account: '',
    checkId : '',
    country: '',
    bankId: '',
    dateBirth: null,
    signedText: '',
    isAuthorized: true,
    agreedPayment: true,
    getStatesCountry : []
  }
}, action) {
    switch (action.type) {
      case 'CLAIM_CASH_SET_DATA_LOOK_UP':
        return {
          ...state,
          dataLookUp: action.payload
        };
      case 'CLAIM_CASH_SET_DATA_DETAIL':
        return {
          ...state,
          dataDetail: action.payload
        };
      case 'CLAIM_CASH_SET_CURRENT_PAYMENT':
        return {
          ...state,
          currentPayment: action.payload
        };
      case 'CLAIM_CASH_STEP1_INPUT':
        return {
          ...state,
          step1Input: action.payload
        };
      case 'CLAIM_CASH_STEP3_INPUT':
        return {
          ...state,
          step3Input: action.payload
        };
      case 'CLAIM_CASH_SET_STEP':
        switch (action.payload) {
          case 1 :
            return {
              ...state,
              steps: {
                step1: {
                  status: true,
                  done: false
                },
                step2: {
                  status: false,
                  done: false
                },
                step3: {
                  status: false,
                  done: false
                }
              }
            }
            case 2 : 
              return {
                ...state,
                steps: {
                  step1: {
                    status: false,
                    done: true
                  },
                  step2: {
                    status: true,
                    done: false
                  },
                  step3: {
                    status: false,
                    done: false
                  }
                }
              }
            case 3 :
              return {
                ...state,
                steps: {
                  step1: {
                    status: false,
                    done: true
                  },
                  step2: {
                    status: false,
                    done: true
                  },
                  step3: {
                    status: true,
                    done: false
                  }
                }
              }
            default:
              return state;
        }
        case 'CLAIM_CASH_RESET':
          return {
            ...state,
            step1Input: {
              merchant: '',
              phoneNumer: '',
              code: ''
            },
            step3Input: {
              contactName: '',
              email: '',
              address: '',
              city: '',
              state: '',
              stateObj: null,
              postcode: '',
              bank: '',
              branch: '',
              account: '',
              checkId: '',
              country: '',
              bankId: '',
              countryObj: null,
              signedText: '',
              isAuthorized: true,
              agreedPayment: true,
              getStatesCountry : []
            },
            dataLookUp: []
        };
      default:
        return state;
    }
  }