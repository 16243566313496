import React, { Component } from 'react';
import '../SignUp.scss';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import Button from '../../../MasterComponents/Button/Button';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import { setFormType } from '../../../config/Company/CompanyActions';

class SignUpPorchDoorChannelLinks extends Component {
  go = (l) => {
    const { history, dispatch } = this.props;
    dispatch(setFormType(l.formType));
    if (l.url) {
      const search = history.location.search ? `&${history.location.search.substring(1)}` : '';
      window.location = `${l.url}${search}`;
    } else {
      history.push(`/platform/brand/${l.code.toLowerCase()}/1${history.location.search}`);
    }
  };
  render() {
    const { links } = this.props;
    return (
      <GridCol>
        <GridRow>
          <GridCol>
            <InputLabel text='What type of business are you representing' title className='title pl-0'/>
          </GridCol>
        </GridRow>
        {
          links.map( l => {
            return (
              <GridRow key={l.id} className='mt-4'>
                <GridCol>
                  <Button onClick={this.go.bind(this, l)} size='md' variant='channel-partner' className='w-100' text={l.description} />
                </GridCol>
              </GridRow>
            )
          })
        }
      </GridCol>
    )
  };
}

export default SignUpPorchDoorChannelLinks;