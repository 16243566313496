import React, { Component, Fragment } from 'react';
import moment from 'moment';
import Card from '../../../MasterComponents/Card/Card';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconEdit from '../../../MasterComponents/Icons/IconEdit';
import IconFolder from '../../../MasterComponents/Icons/IconFolder';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
import config from '../../../config/config';
import Button from '../../../MasterComponents/Button/Button';

class DistributorCardOneDesktop extends Component {

  render() {
    const {
      md, isOrange, statusOnlineColor,
      statusEditColor, statusCredentialsColor, statusOnlineIcon,
      goToEditDistributor, onClickUploadNow, onClickActivate,
      clickActivateEdit, clickActivateDelete, onClickEditCredentials, showHistory
    } = this.props;

    const styleButton = {position:'absolute', bottom: '20px', left: '27px'};

    return (
      <Card onClick={goToEditDistributor} spacing={2} size='py-2 distributor-parent-card' className={'px-1 py-4 px-xl-3 py-xl-4 distributor-card clickable border' + (isOrange ? ' orange' : '')} >
        <GridRow className='pt-0 text-grey text-size-14 justify-content-end text-right clickable' style={{height: '17px'}}>
          { (md.submissionType === 'online' || md.submissionType === 'auto') &&
            <GridCol onClick={clickActivateEdit} size={4} style={{color: statusEditColor}}>
              <IconEdit color={statusEditColor} />
            </GridCol>
          }
          { md.submissionType === 'manual' &&
            <GridCol onClick={clickActivateEdit} size={4} style={{color: statusEditColor}}>
              <IconEdit color={isOrange ? config.colors.rbWhite : config.colors.rbDarkerGrey} /> Edit
            </GridCol>
          }
          { md.submissionType === 'manual' &&
            <GridCol className='text-right' onClick={showHistory}>
              <IconFolder color={isOrange ? config.colors.rbWhite : config.colors.rbDarkerGrey} /> Submission History
            </GridCol>
          }
          <GridCol onClick={clickActivateDelete} className='mw-fc pl-0'>
            <IconDelete color={config.colors.rbDarkerGrey} />
          </GridCol>
        </GridRow>
        <GridRow className='py-0 mb-1 mt-4'>
          <GridCol>
            <h5 className='font-weight-bolder'>{md.distributor}</h5>
          </GridCol>
        </GridRow>
        { ((md.isActive === 1 && md.submissionType !== 'auto') || md.statusType === 'backupSuggested' || md.statusType === 'backupRequired') &&
          <Fragment>
            <GridRow className='text-size-14 text-line-height-20 text-grey text-weight-bold pb-0'>
              <GridCol>
                DCN
              </GridCol>
              <GridCol className='text-right'>
                { md.dcn }
              </GridCol>
            </GridRow>
            <GridRow className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
              <GridCol>
                Submission Type
              </GridCol>
              <GridCol className='text-right'>
                {md.submissionType === 'manual' ? 'Manual Upload' : (md.submissionType === 'auto' ? 'Auto' : (md.submissionType === 'online' ? 'Online Ordering' : "I Don't Know Yet"))}
              </GridCol>
            </GridRow>
            <GridRow className='pt-0 text-size-14 text-line-height-20 text-grey text-weight-bold'>
              { md.submissionType === 'manual' &&
                <Fragment>
                  <GridCol>
                      Last Proof Of Purchase Submission
                  </GridCol>
                  <GridCol size={4} className='text-right'>
                    {moment(md.lastLoadDate).isValid() ? moment(md.lastLoadDate).format('M-DD-YY') : (!md.lastLoadDate ? 'No Data' : md.lastLoadDate)}
                  </GridCol>
                </Fragment>
              }
            </GridRow>
            <GridRow className='pt-0 text-size-14 text-line-height-20 text-grey text-weight-bold'>
              { (md.submissionType === 'online' || (md.statusType === 'backupSuggested' || md.statusType === 'backupRequired') || md.isActive) &&
                <Fragment>
                  <GridCol size={3}>
                     Status
                  </GridCol>
                  <GridCol size={9} className='text-right' style={{color: statusOnlineColor}}>
                    {statusOnlineIcon} &nbsp; {((md.statusMessage && md.statusMessage === 'Processing...') ? 'Pending...' : md.statusMessage) || ((md.status === null && md.statusType === null) && 'Pending...')}
                  </GridCol>
                </Fragment>
              }
              {  md.submissionType === 'manual' &&
                <Fragment>
                  <GridCol>
                    Next Scheduled Submission
                  </GridCol>
                  <GridCol size={4} className='text-right'>
                      {moment(md.nextLoadDate).isValid() ? moment(md.nextLoadDate).format('M-DD-YY') : (!md.nextLoadDate ? 'No Data' : md.nextLoadDate)}
                  </GridCol>
                </Fragment>
              }
            </GridRow>
            <GridRow className='pt-0'>
              { md.submissionType === 'manual' && md.statusType !== 'backupSuggested' && md.statusType !== 'backupRequired' &&
                <GridCol>
                  <Button size='sm' variant='white' onClick={onClickUploadNow} className='text-capitalize m-0' text='Upload Now' />
                </GridCol>
              }
              { md.submissionType === 'online' && md.statusType !== 'backupSuggested' && md.statusType !== 'backupRequired' && md.statusType !== 'failedDcn' && md.statusType !== 'failedPassword' &&
                <GridCol>
                  <Button size='smd' variant='white' onClick={onClickEditCredentials} className={'text-capitalize m-0 ' + (statusCredentialsColor ? 'btn-red-online' : '')} text='RECONNECT'/>
                </GridCol>
              }
            </GridRow>
          </Fragment>
        }
        { md.submissionType === 'auto' &&
          <Fragment>
            <GridRow className='mt-2 pb-0'>
              <GridCol>
                DCN
              </GridCol>
              <GridCol className='text-right'>
                { md.dcn }
              </GridCol>
            </GridRow>
            <GridRow className='mt-2'>
              <GridCol className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
                Great news, we are auto connected to this distributor and will collect your Cash Back for you.
              </GridCol>
            </GridRow>
            <GridRow className='mt-1 text-size-14 text-line-height-20 text-grey text-weight-bold'>
              <GridCol className='col-7'>
                Submission Type
              </GridCol>
              <GridCol className='text-right'>
                <span className='font-weight-bold'>Auto</span>
              </GridCol>
            </GridRow>
          </Fragment>
        }
        { !md.isActive && md.submissionType !== 'auto' && md.statusType !== 'backupSuggested' && md.statusType !== 'backupRequired' &&
          <Fragment>
            {
              md.dcn &&
              <GridRow className='mt-2 pb-0'>
                <GridCol>
                  DCN
                </GridCol>
                <GridCol className='text-right'>
                  { md.dcn }
                </GridCol>
              </GridRow>
            }
            <GridRow className='mt-2'>
              <GridCol className='text-size-14 text-line-height-20 text-grey text-weight-bold'>
                Activate this distributor to start getting your cash back
              </GridCol>
            </GridRow>
            <GridRow style={styleButton}>
              <GridCol>
                <Button onClick={onClickActivate} className='clickable' variant='primary' size='sm' text='CONNECT'></Button>
              </GridCol>
            </GridRow>
          </Fragment>
        }
        {
          md.statusType === 'failedPassword' && !md.isActive &&
          <GridRow style={styleButton}>
            <GridCol className='text-center'>
              <Button size='smd' variant='white' onClick={onClickEditCredentials} className={'text-capitalize ' + (statusCredentialsColor ? 'btn-red-online' : '')} text='RECONNECT'/>
            </GridCol>
          </GridRow>
        }
        {
          md.statusType === 'failedDcn' && !md.isActive &&
          <GridRow style={styleButton}>
            <GridCol>
              <Button size='smd' variant='white' onClick={onClickActivate} className={'text-capitalize ' + (statusCredentialsColor ? 'btn-red-online' : '')} text='RECONNECT'/>
            </GridCol>
          </GridRow>
        }
        {
          (md.statusType === 'backupSuggested' || md.statusType === 'backupRequired') &&
          <GridRow style={styleButton}>
            <GridCol>
              <Button onClick={onClickActivate} className='clickable' variant='white' size='md' text='PROVIDE BACKUP'></Button>
            </GridCol>
          </GridRow>
        }
        {
          (md.isActive) &&
          <GridRow style={styleButton}>
            <GridCol>
              <Button className='clickable' variant='white' size='md' text='CONNECTED'></Button>
            </GridCol>
          </GridRow>
        }
      </Card>
    )
  }
}

export default DistributorCardOneDesktop;
