import React, { Component, Fragment } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import config from '../../../../config/config';
import GridCol from '../../../../MasterComponents/Grid/GridCol';
import GridRow from '../../../../MasterComponents/Grid/GridRow';
import DropDownList from '../../../../MasterComponents/DropDownList/DropDownList';
import DropDownListOption from '../../../../MasterComponents/DropDownList/DropDownListOption';
import Input from '../../../../MasterComponents/Input/Input';
import RadioButton from '../../../../MasterComponents/RadioButton/RadioButton';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import IconInformation from '../../../../MasterComponents/Icons/IconInformation';
import IconEye from '../../../../MasterComponents/Icons/IconEye';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import DropDownListSelectCustom from '../../../../MasterComponents/DropDownList/DropDownListSelectCustom'
import Button from '../../../../MasterComponents/Button/Button';
import IconEdit from '../../../../MasterComponents/Icons/IconEdit';
import byGoogle from '../../../../assets/images/powered-by-google.png';
import getPlacesProps, { getStatesProvinces } from '../../../../MasterComponents/Helper/getPlaces';
import { getText } from '../../../../MasterComponents/Helper/getHelper';
import { getIconInformationColor } from '../../../../config/Company/CompanyHelper';

class FormDefaultStepOneInformation extends Component {
  constructor(props){
    super(props);
    this.state = {
      password : {
        type: 'password',
        color: config.colors.rbDarkerGrey
      },
      confirmPassword : {
        type: 'password',
        color: config.colors.rbDarkerGrey
      },
      refferedBySaved: null
    }
    this.getPlacesProps = getPlacesProps.bind(this);
  };
  isValid = () => {
    const { setError, hideSalesRep, requiredSalesRep, hideChannelPartner, showTaxId, requiredChannelPartner, step1Inputs, signUpSalesRep
      // , companyId
    } = this.props;
    const { firstName, lastName, title, businessName, businessAddress, businessPhoneNumber, businessCity, state, businessPostcode, phoneNumber, email, daSalesRep, referredBy, confirmemail, password, passwordRepeat, taxId
          // , platformId
    } = this.refs;
    const fn = firstName.isValid();
    const ln = lastName.isValid();
    const bn = businessName.isValid();
    const ba = businessAddress.isValid();
    const bph = businessPhoneNumber.isValid();
    const bc = businessCity.isValid();
    const s = state.isValid();
    const bpc = businessPostcode.isValid();
    let ph = phoneNumber.isValid();
    const e = email.isValid();
    const ce = confirmemail.isValid();
    const pw = password ? password.isValid() : true; // no password if SalesRepSignUp
    const pwr = passwordRepeat ? passwordRepeat.isValid() : true; // no password if SalesRepSignUp
    const t = title.isValid();
    let saAndCh = false;
    let tid = showTaxId ? taxId.isValid() : true;
    if (hideSalesRep) {
      if (hideChannelPartner || referredBy.isValid()) {
        if (!hideChannelPartner) {
          referredBy.setValid();
        }
        saAndCh = true;
      }
    } else {
      const sr = daSalesRep.isValid();
      const refby = hideChannelPartner ? true : referredBy.isValid();
      if (( sr || refby ) && ((requiredSalesRep || signUpSalesRep) ? sr : true) && (requiredChannelPartner ? refby : true)) {
        daSalesRep.setValid();
        if (!hideChannelPartner) {
          referredBy.setValid();
        }
        saAndCh = true;
      }
    }

    if (!ba || !bc || !s || !bpc) {
      if (!this.props.showAddressDetails) {
        this.props.toggleAddressDetails();
      }
    }

    if (!ph) {
      if (step1Inputs.phoneNumber === '') {
        phoneNumber.setValid();
        ph = true;
      }
    }

    // if (signUpSalesRep && companyId === 3 && !platformId.isValid()) {
    //   return false;
    // }

    if (fn && ln && bn && ba && bph && bc && s && bpc && ph && e && ce && pw && pwr && t && tid && saAndCh) {
      if (password && passwordRepeat && password.props.value !== passwordRepeat.props.value) {
        password.setInvalid();
        passwordRepeat.setInvalid();
        setError("Passwords don't match.");
        return false;
      }
      if (email.props.value !== confirmemail.props.value) {
        email.setInvalid();
        confirmemail.setInvalid();
        setError("Emails don't match.");
        return false;
      }

      return true;
    }
    setError('Required fields missing.');
    return false;
  };
  validateEmail = () => {
    const { email } = this.refs;
    const { validateEmailFromApi, step1Inputs } = this.props;
    const e = email.isValid();

    if (e) {
      validateEmailFromApi(step1Inputs.email);
    }
  };
  invalidEmail = () => {
    const { email } = this.refs;
    email.setInvalid();
  };
  invalidConfirmEmail = () => {
    const { confirmemail } = this.refs;
    confirmemail.setInvalid();
  };
  getMergedDaSalesReferredBySelectList = () => {
    const {mergedDaSalesRepReferredFromApi} = this.props;

    const options = mergedDaSalesRepReferredFromApi.map((item) => {
      return {
        label: item.name,
        value: item.id,
        platformId: item.platformId,
        isDaSalesRep: item.isDaSalesRep
      }
    });
    return options;
  };
  getSalesRepresentativeList = () => {
    const {daSalesRepFromApi} = this.props;
    const options = daSalesRepFromApi.map((item) => {
      return {
        label: item.name,
        value: item.id,
        platformId: item.platformId,
        isDaSalesRep: item.isDaSalesRep
      }
    });
    return options;
  };
  selectSalesRepresentative = (value) => {
    const { hideChannelPartner } = this.props;
    const { referredBy } = this.refs;
    if (!hideChannelPartner) {
      referredBy.setValid();
    }
    this.props.selectDASalesRep(value);
  };
  getReferredList = () => {
    const { referredPartnerFromApi, filterChannelPartner } = this.props;
    const options = referredPartnerFromApi.map((item) => {
      return {
        label: item.name,
        value: item.id,
        platformId: item.platformId,
        isDaSalesRep: item.salesRepId
      };
    });

    if (filterChannelPartner) {
      return options.filter(o => o.label.toString().toLowerCase().search(filterChannelPartner.toLowerCase()) !== -1);
    }
    return options;
  };
  selectReferred = (value) => {
    const { requiredSalesRep, hideSalesRep, signUpSalesRep } = this.props;
    const { daSalesRep } = this.refs;
    if (!hideSalesRep && !(requiredSalesRep || signUpSalesRep)) {
      daSalesRep.setValid();
    }
    this.props.selectReferredBy(value);
  };
  showPassword = () => {
    this.setState({
      password: {
        type: this.state.password.type === 'text' ? 'password' : 'text',
        color: this.state.password.color === config.colors.rbDarkerGrey ? config.colors.primary : config.colors.rbDarkerGrey
      }
    });
  };
  showConfirmPassword = () => {
    this.setState({
      confirmPassword: {
        type: this.state.confirmPassword.type === 'text' ? 'password' : 'text',
        color: this.state.confirmPassword.color === config.colors.rbDarkerGrey ? config.colors.primary : config.colors.rbDarkerGrey
      }
    });
  };

  render() {
    const {props} = this;
    const { updateNumberLocations, changeHandlerInputs, selectTitle,
      selectStateContactInformation, disabledRefferedBy, companyText,
      companyMaxLocations, requiredSalesRep, showTaxId, showAddressDetails,
      toggleAddressDetails, addressSearch, handleAddressSelect, hideChannelPartner, country,
      signUpSalesRep, companyId, requiredChannelPartner, hideDistributorSalesRep } = this.props;
    const iconInformationColor = getIconInformationColor(companyId);
    let arrayNumberLocations = [];
    for (let i = 0; i < companyMaxLocations; i++) {
      arrayNumberLocations.push(i+1);
    }
    const locationOptions = arrayNumberLocations.map((name, i) => (
      <DropDownListOption onClick={updateNumberLocations} key={`loc_opt_${i}`} title={name} />
    ));

    const styleEye = {
      position: 'relative',
      top: '-28px',
      right: isTablet ? '-96%' : '-92%'
    };

    const styleLocation = {
      top: '0px',
    };

    return (
      <Fragment>
        { signUpSalesRep &&
          <GridRow>
            <GridCol>
              <RadioButton name='signUpType' value='SalesRepSignUp' text='Sign Up with Sales Rep' checked={signUpSalesRep} onChange={changeHandlerInputs}>
                <span style={{marginLeft: '10px'}} className='clickable' data-toggle='tooltipContactName' title='Select this if Sales Rep is filling this form for you.'><IconInformation color={iconInformationColor} size={13} /></span>
              </RadioButton>
            </GridCol>
          </GridRow>
        }
        <GridRow>
          <GridCol>
            <InputLabel text={companyText.contactInformationHeader} title className='title'/>
          </GridCol>
        </GridRow>
        <GridRow className='line-form'>
          <GridCol className='form-group'>
            <InputLabel text='First Name *'/>
            <Input ref='firstName' type='text' name="firstName" value={props.step1Inputs.firstName || ''} onChange={changeHandlerInputs} validate min={2} max={100} placeholder='First Name' />
          </GridCol>
          <GridCol className='form-group'>
            <InputLabel text='Last Name *'/>
            <Input ref='lastName' type='text' name="lastName" value={props.step1Inputs.lastName || ''} onChange={changeHandlerInputs} validate min={2} max={100} placeholder='Last Name' />
          </GridCol>
          <GridCol className='form-group'>
            <InputLabel text='Title *'/>
            <DropDownList ref='title' title={props.step1Inputs.title}>
              {config.signUpStepOneTitleOptions.map((title, i) =>
                <DropDownListOption key={`title_opt_${i}`} title={title} onClick={selectTitle} />
              )}
            </DropDownList>
          </GridCol>
        </GridRow>
        <GridRow className='line-form'>
          <GridCol className='form-group'>
            <InputLabel text='Parent Company Business Name *'/>{<span style={{marginLeft: '10px'}} className='clickable' data-toggle='tooltipContactName' title='The name your rebate check should be made out to'><IconInformation color={iconInformationColor} size={13} /></span>}
            <Input ref="businessName" type='text' name="businessName" value={props.step1Inputs.businessName || ''} onChange={changeHandlerInputs} validateCharacter min={3} max={100} placeholder='Business Name' />
          </GridCol>
          {
            showTaxId ?
            <GridCol className='form-group'>
              <InputLabel text='Tax ID *' />
              <Input ref="taxId" type='text' name="taxId" value={props.step1Inputs.taxId || ''} onChange={changeHandlerInputs} validate min={3} max={200} placeholder='Tax ID' />
            </GridCol>
            : null
          }
        </GridRow>
        <GridRow className='line-form align-items-center'>
          <GridCol className='form-group mb-0' size={12}>
            <InputLabel text='Business Address' />
          </GridCol>
        </GridRow>
        <GridRow className='line-form align-items-center' style={{marginBottom: '16px', flexDirection: 'row'}}>
          <GridCol className='form-group m-0'>
            <DropDownListSelectCustom {...this.getPlacesProps(country, 'optionsPlace', addressSearch, handleAddressSelect)} />
          </GridCol>
          <GridCol style={{maxWidth: '70px'}}>
            <Button onClick={toggleAddressDetails} style={{width: '40px', height: '40px'}} variant={ showAddressDetails ? 'blue' : 'white'} icon={<IconEdit color={ !showAddressDetails ? config.colors.primary : config.colors.rbWhite} />}/>
          </GridCol>
          {
            isMobile ? null :
            <GridCol style={{maxWidth: '120px'}}>
              <img className='w-100' src={byGoogle} alt='google' />
            </GridCol>
          }
        </GridRow>
        {
          isMobile ?
          <GridRow className='line-form align-items-center' style={{marginBottom: '16px'}}>
            <GridCol style={{maxWidth: '120px'}}>
              <img className='w-100' src={byGoogle} alt='google' />
            </GridCol>
          </GridRow>
          : null
        }
        <GridRow className={'mx-0' + (showAddressDetails ? '' : ' d-none')} style={{paddingLeft: '15px', marginBottom: '16px'}}>
          <GridCol className='py-3 pl-0' style={{backgroundColor: '#F8F8F8'}}>
            <GridRow className='line-form'>
              <GridCol className={(isMobile ?  'col-12': 'col-6') + ' form-group'}>
                <InputLabel text='Address Line *' />
                <Input ref="businessAddress" type='text' name="businessAddress" value={props.step1Inputs.businessAddress || ''} onChange={changeHandlerInputs} validate min={3} max={200} placeholder='Business Address' />
              </GridCol>
              <GridCol className={(isMobile ?  'col-12': 'col-6') + ' form-group'}>
                <InputLabel text='Phone Number *' />
                <Input ref='businessPhoneNumber' type='tel' name='businessPhoneNumber' value={props.step1Inputs.businessPhoneNumber || ''} onChange={changeHandlerInputs} validate min={3} max={50} placeholder='Business Phone Number' />
              </GridCol>
            </GridRow>
            <GridRow className='line-group'>
              <GridCol className={(isMobile ?  'col-12': 'col-4') + ' form-group'}>
                <InputLabel text='City *'/>
                <Input ref="businessCity" type='text' name="businessCity" value={props.step1Inputs.businessCity || ''} onChange={changeHandlerInputs} validate min={3} max={100} placeholder='Business City' />
              </GridCol>
              <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                <InputLabel text={getText(country, 'state')}/>
                <DropDownListSelect
                  name='state' ref='state'
                  value={props.step1Inputs.businessStateObj} onChange={selectStateContactInformation}
                  options={getStatesProvinces(country)}/>
              </GridCol>
              <GridCol className={(isMobile ?  'col-6': 'col-4') + ' form-group'}>
                <InputLabel text={getText(country, 'zipCode')}/>
                <Input ref="businessPostcode" type='zipCode' name="businessPostcode" value={props.step1Inputs.businessPostcode || ''} onChange={changeHandlerInputs} country={country} validate placeholder={getText(country, 'zipCodeBusiness')} />
              </GridCol>
            </GridRow>
          </GridCol>
        </GridRow>
        <GridRow className='line-form'>
          <GridCol className='form-group'>
            <InputLabel text='Contact Mobile Number'/>
            <Input ref='phoneNumber' type='tel' name='phoneNumber' value={props.step1Inputs.phoneNumber || ''} onChange={changeHandlerInputs} validate min={3} max={50} placeholder='Contact Phone Number' />
          </GridCol>
          <GridCol className='form-group'>
            <InputLabel text='Email *'/>
            <Input ref='email' type='email' name='email' value={props.step1Inputs.email || ''} onChange={changeHandlerInputs} onBlur={this.validateEmail} validate min={3} max={100} placeholder='Contact Email' />
          </GridCol>
          <GridCol className='form-group'>
            <InputLabel text='Confirm Email *'/>
            <Input ref='confirmemail' type='email' name='emailRepeat' value={props.step1Inputs.emailRepeat || ''} onChange={changeHandlerInputs} validate min={3} max={100} placeholder='Repeat Email' />
          </GridCol>
        </GridRow>
        { !signUpSalesRep &&
        <GridRow className='line-form'>
          <GridCol className='form-group m-0' size={isMobile ? 12 : 6}>
            <InputLabel text='Password *'/> &nbsp;<span className='clickable' data-toggle='tooltipPassword' title='Password must be greater than 5 characters'><IconInformation color={iconInformationColor} size={13} /></span>
            <Input ref='password' type={this.state.password.type} name='password' value={props.step1Inputs.password || ''} onChange={changeHandlerInputs} validate min={6} max={50} placeholder='******' />
            <span className='clickable' style={styleEye} onClick={this.showPassword}><IconEye color={this.state.password.color} size={18} /></span>
          </GridCol>
          <GridCol className='form-group m-0' size={isMobile ? 12 : 6}>
            <InputLabel text='Confirm Password *'/>
            <Input ref='passwordRepeat' type={this.state.confirmPassword.type} name='passwordRepeat' value={props.step1Inputs.passwordRepeat || ''} onChange={changeHandlerInputs} validate min={6} max={50} placeholder='******' />
            <span className='clickable' style={styleEye} onClick={this.showConfirmPassword}><IconEye color={this.state.confirmPassword.color} size={18} /></span>
          </GridCol>
        </GridRow>
        }
        <GridRow className='line-form'>
          {
            !props.hideSalesRep ?
            <GridCol className='form-group' size={isMobile ? 12 : 6}>
              <InputLabel text={(requiredSalesRep || signUpSalesRep) ? 'Sales Rep *' : 'Sales Rep'} />
              <DropDownListSelectCustom
                name='daSalesRep' ref='daSalesRep'
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                value={props.step1Inputs.daSalesRep} onChange={this.selectSalesRepresentative}
                options={this.getSalesRepresentativeList()}
                blurInputOnSelect={true}
                openMenuOnClick={false}
                onInputChange={3}
                isClearable
                menuIsOpen = {false}
              />
            </GridCol>
            : null
          }
          {
            !hideChannelPartner &&
            <GridCol className='form-group' size={isMobile ? 12 : 6}>
              <InputLabel text={`Channel Partner${requiredChannelPartner ? ' *' : ''}`} />
              <DropDownListSelectCustom
                name='referredBy' ref='referredBy'
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                value={props.step1Inputs.referredBy} onChange={this.selectReferred}
                options={this.getReferredList()}
                blurInputOnSelect={true}
                openMenuOnClick={false}
                onInputChange={3}
                isClearable
                menuIsOpen = {false}
                isDisabled={disabledRefferedBy}
              />
            </GridCol>
          }
        </GridRow>
        <GridRow className='line-form'>
          <GridCol className='form-group' size={isMobile ? 12 : 6} style={styleLocation}>
            <InputLabel text={companyText.reportingLocations} />
            { signUpSalesRep && companyId === 3 ?
              <Input ref='locations' type='number' validate min={1} max={companyMaxLocations} name='numberLocations' value={props.step1Inputs.numberLocations || ''} onChange={changeHandlerInputs} />
            :
            <DropDownList ref='locations' top title={props.numberLocations}>
              {locationOptions}
            </DropDownList>
            }
          </GridCol>
          { signUpSalesRep && companyId === 3 &&
            <GridCol className='form-group' size={isMobile ? 12 : 6}>
              <InputLabel text='Platform ID' />
              <Input ref='platformId' type='text' name='platformId' value={props.step1Inputs.platformId || ''} onChange={changeHandlerInputs} requiredCL validateCL placeholder='Platform ID' />
            </GridCol>
          }
          { props.step1Inputs.referredBy && !hideDistributorSalesRep &&
            <GridCol className='form-group' size={isMobile ? 12 : 6}>
              <InputLabel text='Distributor Sales Rep (optional)'/>
              <Input ref='distributorSalesRep' type='text' name="distributorSalesRep" value={props.step1Inputs.distributorSalesRep || ''} onChange={changeHandlerInputs} validate min={3} max={100} placeholder='Distributor Sales Rep' />
            </GridCol>
          }
        </GridRow>
      </Fragment>
    )
  }
}

export default FormDefaultStepOneInformation;
