import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import '../SignUp.scss';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import FormDefault from './Default/FormDefault';
import FormPorchDoor from './PorchDoor/FormPorchDoor';
import FormExistingUser from './ExistingUser/FormExistingUser';
import config from '../../../config/config';
import SignUpFormHeader from './SignUpFormHeader';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import IconClose from '../../../MasterComponents/Icons/IconClose';

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        const { search } = props.history.location;

        let campaign = null;

        if (search) {
            const qp = queryString.parse(search);
            if (qp.campaign) campaign = qp.campaign;
        }

        this.state = {
            campaign: campaign
        }
      };
    getForm = () => {
        const { formType, step, isPorchDoor, history, initReferredBy, pathname, brandSearch, signUpSalesRep } = this.props;
        const { search } = this.props.history.location;
        let customReferredBy = initReferredBy;
        let referredBrandSearch = null;
        let channel = null;
        if (search) {
            const qp = queryString.parse(search);
            if (qp.p) {
                customReferredBy = null;
                referredBrandSearch = qp.p;
            }
          
            if (qp.c) channel = qp.c;
        } else {
            referredBrandSearch = brandSearch ? brandSearch : null;
        }
        const props = {
            step,
            isPorchDoor,
            history,
            initReferredBy: customReferredBy,
            pathname,
            autofillInputs: this.autofill(),
            referredBrandSearch,
            signUpSalesRep,
            campaign: this.state.campaign
        };

        switch (formType) {
            case "default":
                switch(channel) {
                    case "brady":
                        return <FormDefault {...props} requiredChannelPartner initReferredBy={null} filterChannelPartner='brady' />
                    case "reinhart":
                        return <FormDefault {...props} requiredChannelPartner initReferredBy={null} filterChannelPartner='Reinhart' />
                    case "sepg":
                        return <FormDefault {...props} requiredChannelPartner initReferredBy={null} filterChannelPartner='Southeastern Paper' />
                    default:
                        return <FormDefault {...props} />
                }
            case "default_required_cp":
                return <FormDefault {...props} requiredChannelPartner />
            case "default_ss":
                return <FormDefault {...props} requiredChannelPartner initReferredBy={null} filterChannelPartner='Southstar Essentials' />
            case "default_vh":
                return <FormDefault {...props} requiredChannelPartner initReferredBy={null} filterChannelPartner='Valet' />
            case "default_canada":
                return <FormDefault {...props} country='ca' />
            case "default_almost_done":
            case "default_almost_savemore":
                return <FormDefault {...props} showAlmostDone />
            case "default_canada_almost_done":
                return <FormDefault {...props} country='ca' showAlmostDone />
            case "default_required_sales_rep":
                return <FormDefault {...props} requiredSalesRep/>
            case "default_canada_required_sales_rep":
                return <FormDefault {...props} country='ca' requiredSalesRep/>
            case "default_hide_channel_partner":
                return <FormDefault {...props} hideChannelPartner/>
            case "default_canada_hide_channel_partner":
                return <FormDefault {...props} country='ca' hideChannelPartner/>
            case "default_hide_sales_rep":
                return <FormDefault {...props} hideSalesRep />
            case "default_canada_hide_sales_rep":
                return <FormDefault {...props} country='ca' hideSalesRep />
            case "default_no_lock":
                return <FormDefault {...props} initReferredBy={null} />
            case "default_canada_no_lock":
                return <FormDefault {...props} country='ca' initReferredBy={null} />
            case "default_show_tax_id":
                return <FormDefault {...props} showTaxId />
            case "default_canada_show_tax_id":
                return <FormDefault {...props} country='ca' showTaxId />
            case "porchdoor":
                return <FormPorchDoor {...props} />
            case "porchdoor_cool_school":
                return <FormPorchDoor {...props} isCoolSchool hideFSR initReferredBy={{label: 'Cool School Cafe', value: 'CP-004079'}} />
            case "porchdoor_arrowstream":
                return <FormPorchDoor {...props} brandCode='cc' showTitle showReportingLocations hideFSR brandName='Consolidated Concepts' referredPartnerBrand='cc' isArrowStream initReferredBy={{label: 'Arrowstream', value: 'CP-004729'}} />
            case "porchdoor_canada":
                return <FormPorchDoor {...props} country='ca' />
            case "porchdoor_no_lock":
                return <FormPorchDoor {...props} initReferredBy={null} />
            case "porchdoor_reinhart":
                return <FormPorchDoor {...props} filterChannelPartner='Reinhart' initReferredBy={null} />
            case "existing-user":
                return <FormExistingUser {...props} />
            case "existing-user-hide-sales-rep":
                return <FormExistingUser {...props} hideSalesRep />
            case "default_dvpg":
                return <FormDefault {...props} hideLegalForm={true}/>
            case "default_reinhart":
                return <FormDefault {...props} requiredChannelPartner initReferredBy={null} filterChannelPartner='Reinhart' />
            case "default_rasi":
                return <FormDefault {...props} hideDistributorSalesRep/>
            default:
                return null;
        }
    };
    autofill = () => {
        const { search } = this.props.history.location;
        if (search) {
            const qp = queryString.parse(search);
            const { user_email, user_phone, user_first_name, user_last_name, parent_company_name, parent_company_address_street, parent_company_address_city, parent_company_address_zip, parent_company_address_state, external_parent_merchant_id } = qp;
            const { country } = this.props;
            const getStateObj = (initials) => {
                const stateExist = country === 'Canada' ? config.caProvinces.filter( s => s.abbreviation === initials.toUpperCase() || s.name.toLowerCase() === initials.toLowerCase() )[0] : config.usStates.filter( s => s.abbreviation === initials.toUpperCase() || s.name.toLowerCase() === initials.toLowerCase() )[0];
                if (stateExist) {
                    return {
                        label: stateExist.name,
                        value: stateExist.abbreviation
                    }
                }
                return null;
            };
            let userPhone = '';
            if (user_phone) {
                const up = user_phone.replace('-', '').replace('-', '').replace('-','');
                userPhone = `(${up.substring(0,3)}) ${up.substring(3,6)}-${up.substring(6,10)}`;
            }
            const stateObj = parent_company_address_state ? getStateObj(parent_company_address_state) : null;
            const step1Inputs = {
                email: user_email,
                emailRepeat: user_email,
                firstName: user_first_name ? user_first_name : '',
                lastName: user_last_name ? user_last_name : '',
                businessName: parent_company_name ? parent_company_name : '',
                businessAddress: parent_company_address_street ? parent_company_address_street : '',
                businessPostcode: parent_company_address_zip ? parent_company_address_zip : '',
                businessCity: parent_company_address_city ? parent_company_address_city : '',
                businessStateObj: stateObj,
                businessStateName: stateObj ? stateObj.label : '',
                businessState: stateObj ? stateObj.value : '',
                phoneNumber: userPhone
            };
            const autoFillInputs = {
                step1Inputs,
                externalParentMerchantId: external_parent_merchant_id ? external_parent_merchant_id : null,
                firstLocation: {
                    nickname: step1Inputs.businessName,
                    address: step1Inputs.businessAddress,
                    city: step1Inputs.businessAddress,
                    stateObj: step1Inputs.businessStateObj,
                    state: step1Inputs.businessState,
                    businessStateName: step1Inputs.businessStateName,
                    postcode: step1Inputs.businessPostcode,
                    phoneNumber: userPhone
                }
            };
            return autoFillInputs;
        }
        return null;
    };
    goHome = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    };
    render() {
        const { isPorchDoor, step, title, hideSteps, maxSteps, subtitle } = this.props;
        const headerProps = {
            isPorchDoor,
            activeStep: step,
            title: title && title,
            subtitle,
            hideSteps,
            maxSteps
        }
        return (
            <div className={'right-signup signup-info-form'}>
                <div className='header-main'>
                    <div className={'header-fixed home-header ' + (isPorchDoor ? 'be-blue-bg': '')  }>
                        { isMobile &&
                            <Fragment>
                                <GridCol size={11}className='pt-2 pl-5'>
                                    <InputLabel text='Create Account' title className='title' />
                                </GridCol>
                                <GridCol size={1} className='p-0' onClick={this.goHome}>
                                    <IconClose color={'white'} size={18} />
                                </GridCol>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className='header-signup'>
                    <SignUpFormHeader {...headerProps} />
                </div>
                {this.getForm()}
            </div>
        )
    }
}

export default SignUpForm;
