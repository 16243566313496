import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { claimCashSetStep } from '../ClaimCash/Reducer/ClaimCashAction';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import IconEmailOpen from '../../MasterComponents/Icons/IconEmailOpen';
import IconEdit from '../../MasterComponents/Icons/IconEdit';
import Input from '../../MasterComponents/Input/Input';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import Button from '../../MasterComponents/Button/Button';
import DropDownListSelect from '../../MasterComponents/DropDownList/DropDownListSelect';
import config from '../../config/config';
import Checkbox from '../../MasterComponents/Checkbox/Checkbox';
import FormEditModal from './Modals/FormEditModal';
import ReactHtmlParser from 'react-html-parser';
import { terms } from '../ClaimCash/ClaimCashFormText';

class ClaimCashForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            dateBirthIsValid: true
        }
    }

    submitForm = (code, name) => {
        const { contactName, bank, account, branch, address, city, state, country, postcode, signedText, bankId} = this.refs;
        const {step3Input} = this.props;
        const cn = contactName.isValid();
        const b = bank.isValid();
        const a = account.isValid();
        const bn = branch.isValid();
        const ba = address.isValid();
        const c = city.isValid();
        const co = country.isValid();
        const s = state.isValid();
        const pc = postcode.isValid();
        const st = signedText.isValid();
        const ia = step3Input.isAuthorized;
        if(cn && b && a && bn && ba && c && co && s && pc && st && ia) {
            if(step3Input.country === 'CA') {
                const bid =  bankId.isValid();
                if(bid) {
                    this.props.submitForm(code, name);
                }
            } else {
                this.props.submitForm(code, name);
            }
        }
    }

    getCountries = () => {
        const options = config.countries.map((item) => {
            return {
                label: item.name,
                value: item.abbreviation
            }
        });
        return options;
    }

    editModal = () => {
        const { dispatch } = this.props;
        dispatch(showModal(FormEditModal, 'idFormEditModal', {editFields : this.editFields}));
    }

    editFields = () => {
        this.props.editFields();
    }

    backForm = () => {
        const { dispatch } = this.props;
        dispatch(claimCashSetStep(2));
    }

    changeDate = (event) => {
        const dateVal = event.target.value;
        const dateArray = dateVal.split('/');
        const dateFormat = dateArray.join('-');
        this.props.changeDate('dateBirth', dateFormat);
        this.setState({
            dateBirthIsValid: true
        });
    }

    render() {
        const {steps, step3Input, changeHandlerStep3, companyBrand, selectState, selectCountry, selectChecked } = this.props;
        const brandCode = companyBrand.code.toUpperCase();
        const brandName = companyBrand.name.toUpperCase();
        const branchDigits = step3Input.country === 'CA' ? 0 : 0;
        const branchDigitsMax = step3Input.country === 'CA' ? 99999 : 999999999;
        const countryDigits = step3Input.country === 'CA' ? 99999 : 999999999;
        const accountDigits = step3Input.country === 'CA' ? 999999999999 : 99999999999999999;
        const routingNumber = step3Input.country === 'CA' ? 'Transit Number *' : 'Routing Number *';
        const routingNumberPlaceholder = step3Input.country === 'CA' ? 'Enter transit number' : 'Enter routing number';
        const zipCode = step3Input.country === 'CA' ? 'Postal Code *' : 'ZIP Code *';
        const zipCodePlaceholder = step3Input.country === 'CA' ? 'Enter postal code' : 'Enter zip code';
        let textLegal = `${brandName} utilizes Hyperwallet payment services to deliver payments to you. Such payment services are subject to theHyperwallet Terms of Service <a target='_blank' href='https://www.hyperwallet.com/agreements-terms/' style='color: black;'>https://www.hyperwallet.com/agreements-terms </a> and the Hyperwallet Privacy Policy <a target='_blank' href='https://www.hyperwallet.com/agreements-privacy/' style='color: black;'>https://www.hyperwallet.com/agreements-privacy</a>`
        let textTerms = terms();

        return (
            <Fragment>
                <GridRow>
                    <GridCol className='text-center'>
                        <GridCol className='py-3'>
                            <IconEmailOpen color={config.colors.secondary} size={30} />
                        </GridCol>
                        <GridCol className='pb-5 claim-text1'>
                            Bank Information
                        </GridCol>
                    </GridCol>
                </GridRow>
                { steps.step3.status &&
                    <Fragment>
                        <GridRow className='line-form'>
                            <GridCol size={9}>
                            </GridCol>
                            <GridCol size={3} className='form-group'>
                                <GridCol className='p-2 clickable border text-center'  onClick={this.editModal}>
                                    <IconEdit color={config.colors.rbDarkerGrey} />
                                </GridCol>
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Business Name *'/>
                                <Input ref='contactName' type='text' name='contactName' value={step3Input.contactName || ''} onChange={changeHandlerStep3} validate min={3} max={100} placeholder='Enter business name' disabled={step3Input.contactNameDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Country *'/>
                                <DropDownListSelect
                                    name='country' ref='country'
                                    value={step3Input.countryObj} onChange={selectCountry}
                                    options={this.getCountries()}
                                    isDisabled={step3Input.countryDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Bank Name *'/>
                                <Input ref='bank' type='text' name='bank' value={step3Input.bank || ''} onChange={changeHandlerStep3} validate min={3} max={100} placeholder='Enter bank name' disabled={step3Input.bankDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Account Number *'/>
                                <Input ref='account' type='number' name='account' value={step3Input.account || ''} onChange={changeHandlerStep3} validate min={1} max={accountDigits} placeholder='Enter account number' disabled={step3Input.accountDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text={routingNumber}/>
                                <Input ref='branch' type='number' name='branch' value={step3Input.branch || ''} onChange={changeHandlerStep3} validate min={5} max={countryDigits} placeholder={routingNumberPlaceholder} disabled={step3Input.branchDisabled} />
                            </GridCol>
                        </GridRow>
                        { step3Input.country === 'CA' &&
                            <GridRow className='line-form'>
                                <GridCol className='form-group'>
                                    <InputLabel text='Branch ID *'/>
                                    <Input ref='bankId' type='number' name='bankId' value={step3Input.bankId || ''} onChange={changeHandlerStep3} validate min={branchDigits} max={branchDigitsMax} placeholder='Enter branch ID' disabled={step3Input.bankIdDisabled} />
                                </GridCol>
                            </GridRow>
                        }
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='Business Address *'/>
                                <Input ref='address' type='text' name='address' value={step3Input.address || ''} onChange={changeHandlerStep3} validate min={3} max={100} placeholder='Enter address' disabled={step3Input.addressDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text='City *'/>
                                <Input ref='city' type='text' name='city' value={step3Input.city || ''} onChange={changeHandlerStep3} validate min={3} max={100} placeholder='Enter city' disabled={step3Input.cityDisabled} />
                            </GridCol>
                            <GridCol className='form-group'>
                                <InputLabel text='State/Province *'/>
                                <DropDownListSelect
                                    name='state' ref='state'
                                    value={step3Input.stateObj} onChange={selectState}
                                    options={step3Input.getStatesCountry}
                                    isDisabled={step3Input.stateDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <InputLabel text={zipCode}/>
                                <Input ref='postcode' type='zipCode' name='postcode' value={step3Input.postcode || ''} country={step3Input.country || ''} onChange={changeHandlerStep3} validate placeholder={zipCodePlaceholder} disabled={step3Input.postcodeDisabled} />
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol>
                                <GridRow className='check'>
                                    <GridCol size={2}>
                                        <Checkbox  name='isAuthorized' value={step3Input.isAuthorized} text='' onChange={selectChecked} className={step3Input.isAuthorized ? '' : 'invalid'} />
                                    </GridCol>
                                    <GridCol size={10} className={'col p-0 pt-4' + (isMobile ? '' : ' ') + (step3Input.isAuthorized ? '' : 'text-red')} style={{fontSize:'14px', right: isMobile ? '5px' : '15px'}}>
                                        Acknowledgement, Authorization and Release
                                    </GridCol>                    
                                </GridRow>
                            </GridCol>
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol className='form-group'>
                                <GridCol className='col-12 claim-authorized-div' style={{maxHeight: '150px', overflowX:'hidden', overflowY:'auto'}}>
                                    {ReactHtmlParser(textLegal)}
                                    {ReactHtmlParser(textTerms)}
                                </GridCol>
                            </GridCol>                    
                        </GridRow>
                        <GridRow className='line-form'>
                            <GridCol size={12} className='form-group'>
                                <span style={{fontSize:'14px'}}>Confirm your name and signature</span>                
                            </GridCol>
                            <GridCol size={12} className='form-group'>
                                <InputLabel text='Your Full Name *'/>
                                <Input type='text' placeholder='Full Name' ref='signedText' name='signedText' value={step3Input.signedText} onChange={changeHandlerStep3} validate min={3} max={100} />
                            </GridCol>
                        </GridRow>
                        <GridRow>
                            <GridCol className='form-group'>
                                <InputLabel text='Signed by *'/><br/>
                                <span className='signup-signed'>{step3Input.signedText}</span>                     
                            </GridCol>
                        </GridRow>
                        <GridRow className='pb-5 pt-3'>
                            <GridCol size={isMobile ? 12 : 6}  className='form-group'>
                                <Button type='button' variant='white' size='' text='BACK' onClick={this.backForm}/>    
                            </GridCol>
                            <GridCol size={isMobile ? 12 : 6}>
                                <Button type='button' variant='primary' size='' text='SUBMIT' onClick={this.submitForm.bind(this, brandCode, brandName)}></Button>
                            </GridCol>
                        </GridRow>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default ClaimCashForm;
