import Joi from 'joi';
import React, { useEffect } from 'react';
import { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from '../../../config/config';
import DropDownListOption from '../../../MasterComponents/DropDownList/DropDownListOption';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import LOTAcknowledgementModal from '../../SignUp/Modals/LOTAcknowledgementModal';
import SignUpApi from '../../SignUp/SignUpApi';
import usePlacesForm from './usePlacesForm';
import moment from 'moment';

const SCHEMA = Joi.object({
  firstName: Joi.string().required().min(2).label('First Name'),
  lastName: Joi.string().required().min(2).label('Last Name'),
  businessName: Joi.string().required().min(2).label('Business Name'),
  phoneNumber: Joi.string(),
  email: Joi.string().email({ tlds: {allow: false} }).required().label('Email'),
  password: Joi.string().min(6).max(15).required().label('Password'),
  repeatPassword: Joi.string().valid(Joi.ref('password')).label('Confirm Password').messages({'any.only': `Passwords don't match`}),
  reportingLocations: Joi.number().integer().required(),
  isGpo: Joi.boolean().falsy('No').truthy('Yes'),
  gpoName: Joi.object().when('isGpo', {is: false, then: Joi.allow(null)}).when('isGpo', {is: true, then: Joi.object().required()}),
  terminationDate: Joi.date(),
  isDma: Joi.boolean().falsy('No').truthy('Yes'),
  nameOfCompanies: Joi.array().when('isDma', {is: true, then: Joi.array().min(1)}),
  termsAndConditions: Joi.bool().invalid(false).messages({'any.invalid':'Please check Terms And Conditions'}),
  referredBy: Joi.any(),
  referredName: Joi.any(),
  daSalesRep: Joi.any(),
  smartSalesRep: Joi.any()
});

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  businessName: '',
  phoneNumber: '',
  email: '',
  password: '',
  repeatPassword: '',
  reportingLocations: '1',
  isGpo: 'No',
  gpoName: null,
  terminationDate: new Date(),
  isDma: 'No',
  nameOfCompanies: [],
  termsAndConditions: false,
  referredBy: process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_ID !== undefined ? process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_ID : 'CP-002739',
  referredName: process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_DESCRIPTION !== undefined ? process.env.REACT_APP_COMPANY_DEFAULT_CHANNEL_PARTNER_DESCRIPTION : 'Marketing User',
  daSalesRep: 'Inside Sales',
  smartSalesRep: null
};

const reducer = (state, action) => {
  if (action.field === 'smart') {
    const {
      first_name, last_name, business_name, email, reporting_locations,
      sales_rep, referred_by, referred_name
    } = action.value;

    return {
      ...state,
      firstName: first_name || state.firstName,
      lastName: last_name || state.lastName,
      businessName: business_name || state.businessName,
      email: email || state.email,
      reportingLocations: reporting_locations || state.reportingLocations,
      daSalesRep: sales_rep || state.daSalesRep,
      smartSalesRep: sales_rep || null,
      referredBy: referred_by || state.referredBy,
      referredName: referred_name || state.referredName
    };
  }
  if (action.field === 'nameOfCompanies') {
    return {
      ...state,
      nameOfCompanies: action.value.map(c => {
        if (c.isCustom) {
          return {
            ...c,
            label: c.value
          }
        }
        return c;
      })
    }
  }
  if (action.field === 'daSalesRep') {
    return {
      ...state,
      daSalesRep: state.smartSalesRep || action.value
    }
  }
  return {
    ...state,
    [action.field]: action.value
  }
};

const useForm = (country) => {
  const reduxDispatch = useDispatch();
  const placesForm = usePlacesForm(country);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [invalid, setInvalid] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [nameOfCompaniesTextSearch, setNameOfCompaniesTextSearch] = useState('');
  const [finish, setFinish] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const reportingLocationsText = useSelector(({company}) => company.text.reportingLocations);
  const terminationDateText = useSelector(({company}) => company.text.terminationDate);
  const maxLocations = useSelector(({company}) => company.maxLocations);

  // useEffect(() => {
  //   if (placesForm.state.value) {
  //     let defaultSalesRep = '';

  //     switch (placesForm.state.value.value) {
  //       case 'AL':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'AK':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'AZ':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'AR':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'CA':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'CO':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'CT':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'DC':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'DE':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'FL':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'GA':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'HI':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'ID':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'IL':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'IN':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'IA':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'KS':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'KY':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'LA':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'ME':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'MD':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'MA':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'MI':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'MN':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'MS':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'MO':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'MT':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'NE':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'NV':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'NH':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'NJ':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'NM':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'NY':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'NC':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'ND':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'OH':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'OK':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'OR':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'PA':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'RI':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'SC':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'SD':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'TN':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'TX':
  //         defaultSalesRep = 'Austin Martineau';
  //         break;
  //       case 'UT':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'VT':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'VA':
  //         defaultSalesRep = 'Max Clancy';
  //         break;
  //       case 'WA':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'WV':
  //         defaultSalesRep = 'Eric Cimino';
  //         break;
  //       case 'WI':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       case 'WY':
  //         defaultSalesRep = 'Chris Boyd';
  //         break;
  //       default:
  //         defaultSalesRep = '';
  //         break;
  //     }

  //     dispatch({
  //       field: 'daSalesRep',
  //       value: defaultSalesRep
  //     });
  //   }
  // }, [placesForm.state.value])

  const dispatchSmart = (values) => {
    dispatch({
      field: 'smart',
      value: values
    });
  };

  const toggleAdressDetails = () => {
    setShowAddressDetails(!showAddressDetails);
  };

  const onChange = (value, name) => {
    dispatch({field: name, value: value});
  };

  const onFocus = (name) => {
    setInvalid(invalid.filter(f => f !== name));
  };

  const firstName = {
    onChange,
    value: state.firstName,
    isInvalid: invalid.some(f => f === 'firstName'),
    onFocus: () => onFocus('firstName')
  };

  const lastName = {
    onChange,
    value: state.lastName,
    isInvalid: invalid.some(f => f === 'lastName'),
    onFocus: () => onFocus('lastName')
  };

  const placesAutocompleteProps = {
    placeholder: 'Search Business Name...',
    name: 'googlePlaces',
    blurInputOnSelect: true,
    openMenuOnClick: false,
    filterOption: false,
    onInputChange: 3,
    components: { DropdownIndicator:() => null, IndicatorSeparator:() => null },
    onSearch: (string) => {
      placesForm.onSearch(string);
    },
    menuIsOpen: false,
    value: (state.businessName && {label: state.businessName, value: state.businessName}) || '',
    options: placesForm.predictions,
    onChange: ({label, value: place_id}) => {
      if (place_id) {
        SignUpApi.getPlaceData(place_id).then(({data}) => {
          const { result } = data.data;
          const { address_components, name, formatted_phone_number } = result;
          const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0] || address_components.filter(a => a.types[0] === 'premise')[0];
          const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
          const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
          const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
          const zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];
          placesForm.updateAll({
            street: (`${(streetNumber && streetNumber.long_name) || ''}${street ? ' '+street.long_name : ''}`),
            state: {
              label: (usState && usState.long_name) || '',
              value: (usState && usState.short_name) || ''
            },
            city: (city && city.long_name) || '',
            zipcode: (zipcode && zipcode.long_name.toString().trim()) || '',
          });
          placesForm.setInvalid([]);
          placesForm.inputState.current.setValid();
          setShowAddressDetails(!(streetNumber && street && city && usState && zipcode) || showAddressDetails);
          onChange(name, 'businessName');
          onChange(formatted_phone_number, 'phoneNumber');
        }).catch(err => {
          console.error(err);
        })
      } else {
        onChange(label, 'businessName');
        setShowAddressDetails(true);
      }
    }
  };

  const bnInvalid = invalid.some(f => f === 'businessName');

  const businessName = {
    className: `rb-input form-control${bnInvalid ? ' is-invalid' : ''}`,
    placeholder: 'Business Name',
    onChange,
    onFocus: () => onFocus('businessName'),
    value: state.businessName
  };

  const phoneNumber = {
    onChange,
    value: state.phoneNumber,
    onFocus: () => onFocus('phoneNumber')
  };

  const email = {
    onChange,
    value: state.email,
    isInvalid: invalid.some(f => f === 'email'),
    onFocus: () => onFocus('email')
  };

  const password = {
    onChange,
    value: state.password,
    isInvalid: invalid.some(f => f === 'password' || f === 'repeatPassword'),
    onFocus: () => onFocus('password'),
    type: 'password'
  };

  const repeatPassword = {
    onChange,
    value: state.repeatPassword,
    isInvalid: invalid.some(f => f === 'password' || f === 'repeatPassword'),
    onFocus: () => onFocus('repeatPassword'),
    type: 'password'
  };

  let arrayNumberLocations = [];
  for (let i = 0; i < maxLocations; i++) {
    arrayNumberLocations.push(i+1);
  };

  const reportingLocationsOptions = arrayNumberLocations.map((name, i) => (
    <DropDownListOption onClick={(value) => onChange(value, 'reportingLocations')} key={`loc_opt_${i}`} title={name} />
  ));

  const isGpoOptions = ['Yes', 'No'].map(t => (
    <DropDownListOption onClick={(value) => onChange(value, 'isGpo')} key={`isgpo_opt_${t}`} title={t} />
  ));

  const isGpo = {
    title: state.isGpo,
    value: state.isGpo === 'Yes' ? true : false,
    options: isGpoOptions,
  };

  const isDmaOptions = ['Yes', 'No'].map(t => (
    <DropDownListOption onClick={(value) => onChange(value, 'isDma')} key={`isdma_opt_${t}`} title={t} />
  ));

  const isDma = {
    title: state.isDma,
    value: state.isDma === 'Yes' ? true : false,
    options: isDmaOptions
  };

  const gpoName = {
    value: state.gpoName,
    options: config.gpoNameOptions,
    isSearchable: false,
    isInvalid: invalid.some(f => f === 'gpoName'),
    onChange: (option) => {
      onFocus('gpoName');
      onChange(option, 'gpoName');
      reduxDispatch(showModal(LOTAcknowledgementModal, 'LOTAcknowledgementModal', {
        message: LOTLegalText(option.value),
        acknowledgeLOTTerms: (bool) => {
          onChange(bool ? 'Yes' : 'No', 'isGpo');
          if (!bool)
            onChange(null, 'gpoName');
        }
      }))
    }
  };

  const LOTLegalText = (gpoName) => {
    const today = moment().format('MMMM d, YYYY');
    const parentName = state.businessName;
    return `Effective ${today}, ${parentName} will no longer participate in any programs offered by ${gpoName}. Buyers Edge Platform is hereby authorized to communicate this termination with my former GPO on Member's behalf. However, Member hereby acknowledges that it is the Member's responsibility to provide a copy of the letter of termination ("LOT") to the former GPO of record. A copy of the LOT of termination will be supplied to Member for the purposes of communicating the same to Member's former GPO.`;
  };

  const terminationDate = {
    value: state.isGpo === 'Yes' ? state.terminationDate : null,
    text: terminationDateText,
    datePickerProps: {
      selected: state.terminationDate,
      locale: 'en',
      popperPlacement: "bottom-end",
      dateFormat: 'MM/dd/yyyy',
      disabled: state.isGpo === 'No',
      onChange: (value) => onChange(value, 'terminationDate')
    }
  };

  const searchManufacturers = (search, page) => {
    reduxDispatch(spinner(true));
    SignUpApi.getManufacturers(search, page).then(({data}) => {
      reduxDispatch(spinner(false));
      setManufacturers(data.data.map(m => {
        return {
          ...m,
          label: m.nickname,
          value: m.id
        };
      }));
    })
  };

  const nameOfCompanies = {
    onChange: (value) => onChange(value, 'nameOfCompanies'),
    value: state.nameOfCompanies,
    isMulti: true,
    options: nameOfCompaniesTextSearch === '' ? manufacturers : [{label: 'Add: '+nameOfCompaniesTextSearch, nickname: nameOfCompaniesTextSearch, value: nameOfCompaniesTextSearch, isCustom: true}, ...manufacturers],
    onInputChange: (text) => {
      setNameOfCompaniesTextSearch(text);
      searchManufacturers(text, 1);
    }
  };

  useEffect(() => {
    searchManufacturers('', 1);
    //eslint-disable-next-line
  }, []);

  const validate = () => {
    const validationPlaces = placesForm.validate();
    if (validationPlaces.error) {
      setShowAddressDetails(true);
    }
    const validation = SCHEMA.validate(state, {abortEarly: false, errors: {wrap: {label: ''}}});
    setInvalid(validation.error ? validation.error.details.map(e => e.context.key) : []);
    return (validation.error || validationPlaces.error) ? false : true;
  };

  return {
    dispatchSmart,
    firstName,
    lastName,
    placesAutocompleteProps,
    placesForm,
    businessName,
    phoneNumber,
    email,
    password,
    repeatPassword,
    reportingLocations: {
      value: state.reportingLocations,
      text: reportingLocationsText,
      options: reportingLocationsOptions
    },
    isGpo,
    gpoName,
    terminationDate,
    isDma,
    nameOfCompanies,
    termsAndConditions: {
      checked: state.termsAndConditions,
      onChange: () => {
        setInvalid(invalid.filter(f => f !== 'termsAndConditions'));
        onChange(!state.termsAndConditions, 'termsAndConditions');
      },
      className: `custom-control-input${invalid.some(f => f === 'termsAndConditions') ? ' is-invalid' : ''}`
    },
    validate,
    addressDetails: {
      toggle: toggleAdressDetails,
      value: showAddressDetails
    },
    finish: {
      value: finish,
      set: setFinish
    },
    formValues: state
  };
};

export default useForm;