import { useState } from "react";
import { getText } from "../../../MasterComponents/Helper/getHelper";
import { getStatesProvinces } from "../../../MasterComponents/Helper/getPlaces";
import SignUpApi from "../../SignUp/SignUpApi";

const usePlacesForm = ({dispatch, invalid, index, country, ...state}) => {
  const [predictions, setPredictions] = useState([]);
  const onFocus = (name) => {
    dispatch({type: 'KEY', payload: {
      index: index,
      key: 'invalid',
      value: invalid.filter(f => f !== name)
    }});
  };

  const onChange = (value, name) => {
    dispatch({type: 'KEY', payload: {
      index: index,
      key: name,
      value: value
    }});
  };

  const onSearch = (input) => {
    SignUpApi.getPlaceSearch(input, country).then(({data}) => {
      setPredictions(data.data.predictions.map(pred => {
        return {
          label: pred.description,
          value: pred.place_id
        };
      }));
    }).catch(err => {
      console.error(err);
    })
  }

  const placesAutocompleteProps = {
    placeholder: 'Search Business Name...',
    name: 'googlePlaces',
    blurInputOnSelect: true,
    openMenuOnClick: false,
    filterOption: false,
    onInputChange: 3,
    components: { DropdownIndicator:() => null, IndicatorSeparator:() => null },
    onSearch: (string) => {
      onSearch(string);
    },
    menuIsOpen: false,
    value: (state.name && {label: state.name, value: state.name}) || '',
    options: predictions,
    onChange: ({label, value: placeId}) => {
      if (placeId) {
        SignUpApi.getPlaceData(placeId).then(({data}) => {
          const { result } = data.data;
          const { address_components, name, formatted_phone_number } = result;
          const streetNumber = address_components.filter(a => a.types[0] === 'street_number')[0] || address_components.filter(a => a.types[0] === 'premise')[0];
          const street = address_components.filter(a => a.types[0] === 'route')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
          const city = address_components.filter(a => a.types[0] === 'locality')[0] || address_components.filter(a => a.types[0] === 'neighborhood')[0];
          const usState = address_components.filter(a => a.types[0] === 'administrative_area_level_1')[0];
          const zipcode = address_components.filter(a => a.types[0] === 'postal_code')[0];
          dispatch({
            type: 'FULL_ADDRESS',
            payload: {
              index: index,
              name: name || label,
              phoneNumber: formatted_phone_number || '',
              street: (`${(streetNumber && streetNumber.long_name) || ''}${street ? ' '+street.long_name : ''}`),
              state: {
                label: (usState && usState.long_name) || '',
                value: (usState && usState.short_name) || ''
              },
              city: (city && city.long_name) || '',
              zipcode: (zipcode && zipcode.long_name.toString().trim()) || '',
              addressDetails: !(streetNumber && street && city && usState && zipcode) || state.showAddressDetails
            }
          })
        }).catch(err => {
          console.error(err);
        });
      }
    }
  };

  const nameInvalid = invalid.some(f => f === 'name');

  const locationName = {
    className: `rb-input form-control${nameInvalid ? ' is-invalid' : ''}`,
    placeholder: 'Search Location Name...',
    onFocus: () => onFocus('name'),
    onChange: () => onChange('name'),
    value: state.name || ''
  };

  const addressDetails = {
    value: state.addressDetails,
    toggle: () => onChange(!state.addressDetails, 'addressDetails')
  };

  const fullAddressInvalid = invalid.some(f => f === 'street' || f === 'city' || f === 'state' || f === 'zipcode');
  const fullAddress = {
    name: 'fullAddress',
    placeholder: 'Location Address',
    disabled: true,
    value: (`${state.street ? state.street : ''}`) + (state.city ? `, ${state.city}` : '') + (state.state ? `, ${state.state.value}` : '') + (state.zipcode ? `, ${state.zipcode}` : ''),
    className: `bg-white rb-input form-control${fullAddressInvalid ? ' is-invalid' : ''}`,
  };

  const phoneNumber = {
    name: 'phoneNumber',
    placeholder: 'Phone Number',
    onChange,
    value: state.phoneNumber || '',
    onFocus: () => onFocus('phoneNumber')
  };

  const street = {
    name: 'street',
    placeholder: 'Address Line',
    onChange,
    value: state.street,
    isInvalid: invalid.some(f => f === 'street'),
    onFocus: () => onFocus('street')
  };

  const city = {
    name: 'city',
    placeholder: 'city',
    onChange,
    value: state.city,
    isInvalid: invalid.some(f => f === 'city'),
    onFocus: () => onFocus('city')
  };

  const usState = {
    name: 'state',
    onChange: (value) => onChange(value, 'state'),
    value: state.state,
    onFocus: () => onFocus('state'),
    isInvalid: invalid.some(f => f === 'state'),
    options: getStatesProvinces(country)
  };

  const zipcode = {
    name: 'zipcode',
    placeholder: getText(country, 'zipCode'),
    type: 'zipCode',
    country: country,
    onChange,
    value: state.zipcode,
    isInvalid: invalid.some(f => f === 'zipcode'),
    onFocus: () => onFocus('zipcode')
  };

  return {
    placesAutocompleteProps,
    locationName,
    addressDetails,
    fullAddress,
    phoneNumber,
    street,
    city,
    usState,
    zipcode
  };
};

export default usePlacesForm;